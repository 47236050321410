import { InputAdornment, Stack, TextField } from "@mui/material";
import { CustomModal } from "@/components/Modal";
import { PageTitle } from "@/components/Title";
import { BlueSectionPaper } from "@/features/acquireBook/components";
import { CheckboxesToSelectBookOptions } from "@/utils/utilsAcquireBook";
import { useValidator } from "@/features/realEstateReceptionBookFeed/hooks/useValidator";
import { useState } from "react";
import { type GridSelectionModel } from "@mui/x-data-grid";
import { type MonitoringDataGridRow } from "../types";
import { ButtonVariantOption, SubmitButton } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { type AcquireBookForm } from "../types/schemas";

interface IProps {
  acquireBookOpen: boolean;
  handleAcquireBookAPI: (params: AcquireBookForm) => Promise<void>;
  handleClose: () => void;
  rows: MonitoringDataGridRow[];
  selectedIds: GridSelectionModel;
  setAcquireBooksRows: React.Dispatch<
    React.SetStateAction<MonitoringDataGridRow[]>
  >;
}

export const AcquireBookModal: React.FC<IProps> = (props: IProps) => {
  const {
    acquireBookOpen,
    handleAcquireBookAPI,
    handleClose,
    rows,
    selectedIds,
    setAcquireBooksRows,
  } = props;
  const validator = useValidator();
  const selectedRow = rows.find((row) => row.id === selectedIds[0]);

  const initRequestOptions: Record<string, Record<string, boolean>> = {
    syoyusya: { checked: false, disable: true },
    zennbu: { checked: false, disable: true },
    sintaku: { checked: false, disable: true },
    kyodo: { checked: false, disable: true },
    genzai: { checked: false, disable: true },
    tiseki: { checked: false, disable: true },
    densi: { checked: false, disable: true },
    tieki: { checked: false, disable: true },
    tatemono: { checked: false, disable: true },
  };

  const [requestOptions, setRequestOptions] = useState<
    Record<string, Record<string, boolean>>
  >(
    selectedRow != null
      ? {
          syoyusya: {
            checked: selectedRow.syoyusya.checked,
            disable: selectedRow.syoyusya.disabled,
          },
          zennbu: {
            checked: selectedRow.zennbu.checked,
            disable: selectedRow.zennbu.disabled,
          },
          sintaku: {
            checked: selectedRow.sintaku.checked,
            disable: selectedRow.sintaku.disabled,
          },
          kyodo: {
            checked: selectedRow.kyodo.checked,
            disable: selectedRow.kyodo.disabled,
          },
          genzai: {
            checked: selectedRow.genzai.checked,
            disable: selectedRow.genzai.disabled,
          },
          tiseki: {
            checked: selectedRow.tiseki.checked,
            disable: selectedRow.tiseki.disabled,
          },
          densi: {
            checked: selectedRow.densi.checked,
            disable: selectedRow.densi.disabled,
          },
          tieki: {
            checked: selectedRow.tieki.checked,
            disable: selectedRow.tieki.disabled,
          },
          tatemono: {
            checked: selectedRow.tatemono.checked,
            disable: selectedRow.tatemono.disabled,
          },
        }
      : initRequestOptions
  );

  /**
   * チェックボックスが操作されたときにチェックの状態を更新する。
   * @param {Record<string, Record<string, boolean>>} newRequestOptions
   */
  const handleCheckboxChange = (
    newRequestOptions: Record<string, Record<string, boolean>>
  ): void => {
    const acquireBooksRows = rows.map((row) => {
      if (row.id === selectedIds[0]) {
        return {
          ...row,
          ...newRequestOptions,
        };
      }

      return row;
    });

    setAcquireBooksRows(acquireBooksRows);
  };

  return (
    <CustomModal
      isOpen={acquireBookOpen}
      handleClose={() => {
        setRequestOptions(initRequestOptions);
        handleClose();
      }}
      ariaLabelledby="acquire-books-modal"
      ariaDescribedby="acquire-books-modal-description"
    >
      <Stack direction={"column"} spacing={3}>
        {/* タイトル */}
        <PageTitle>謄本及び図面取得</PageTitle>

        {/* 選択したモニタリング詳細の情報表示部分 */}
        <Stack direction={"row"} spacing={1}>
          <TextField fullWidth id="identifyName" label="識別名" type="text" />
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <TextField
            defaultValue={selectedRow?.location}
            fullWidth
            id="location"
            label="所在"
            type="text"
          />
          <TextField
            defaultValue={selectedRow?.chibanKaokuNumber}
            fullWidth
            id="chibanKaokuNumber"
            label="地番または家屋番号"
            type="text"
          />
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <TextField
            defaultValue={0}
            fullWidth
            id="pastDays"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  日
                  <Tooltip
                    title="取得済の場合でも経過日数を過ぎたものは再取得いたします"
                    placement="bottom"
                  >
                    <HelpIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            label="前回取得日からの経過日数を指定する"
            type="number"
          />
        </Stack>
      </Stack>

      <Stack direction={"column"} sx={{ mt: 3 }}>
        {/* チェックボックス群 */}
        <BlueSectionPaper>
          <CheckboxesToSelectBookOptions
            requestOptions={requestOptions}
            setRequestOptions={setRequestOptions}
            realEstateTypeEng={
              selectedRow != null ? selectedRow.realEstateType : "LAND"
            }
            onChange={(
              event: boolean,
              key: string,
              requestOptions: Record<string, Record<string, boolean>>
            ) => {
              validator.checkOptions.validate(requestOptions);

              handleCheckboxChange(requestOptions);
            }}
          />
        </BlueSectionPaper>

        {/* 説明 */}
        <ul>
          <li>最新の不動産登記/図面を取得いたします</li>
          <li>取得済みの場合は、課金が発生いたしません</li>
        </ul>
      </Stack>

      {/* ボタン群 */}
      <Stack direction={"row"} justifyContent={"center"} sx={{ mt: 2 }}>
        <SubmitButton
          label="取得する"
          onClick={(_, cancelLoading) => {
            (async () => {
              await handleAcquireBookAPI({
                identifyName: "",
                location: selectedRow?.location,
                chibanKaokuNumber: selectedRow?.chibanKaokuNumber,
                pastDays: 0,
              });
              setRequestOptions(initRequestOptions);
              handleClose();
            })();
          }}
          size={SizingWrapperStyle.SMALL}
          timeout={30000}
          variant={ButtonVariantOption.Contained}
        />
      </Stack>
    </CustomModal>
  );
};
