import { numberFormat } from "@/utils/utils";

/**
 * 種別の英語を日本語に変換する。
 * @param {string} value
 * @returns {string}
 */
const convertTypeEnglishToJapanese = (value: string): string => {
  if (value === "LAND") {
    return "土地";
  } else if (value === "BUILDING") {
    return "建物";
  } else if (value === "CONDOMINIUM") {
    return "区分建物";
  } else if (value === "ONE_BUILDING") {
    return "一棟";
  } else {
    return "";
  }
};

/**
 * 推計地積の値をフォーマット(少数第2位を切り捨て)
 * @param area 地積の値。nullの場合はデフォルト値として "—" を返す。
 * @return フォーマットされた推計地積の値。nullの場合は "—"。
 **/
const formatArea = (area: number | null): string => {
  return area ? numberFormat(area, { maximumFractionDigits: 2 }, "floor") : "—";
};

export { convertTypeEnglishToJapanese, formatArea };
