import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  type SelectChangeEvent,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Label } from "@/components/Label";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import PersonIcon from "@mui/icons-material/Person";
import React, { useState } from "react";
import {
  getPrefectureMenuItems,
  getPrefectureNameById,
} from "@/utils/utilsAcquireBook";
import { useValidator } from "@/features/commercialBook/hooks/useValidator";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { SizingWrapper, SizingWrapperStyle } from "@/components/Wrapper";
import { Button, ButtonVariantOption } from "@/components/Button";
import { useCity } from "@/hooks/useCity";
import { type CityListResponse } from "@/types/city";
import { useNavigate } from "react-router-dom";
import { useFeatureFlags } from "@/configs/featureFlag";

export const CommercialBook: React.FC = () => {
  // 都道府県セレクトボックス
  const [prefecture, setPrefecture] = useState<string>("");

  // 商業・名称テキストボックス
  const [commercialBookName, setStateCommercialBookName] = useState<string>("");

  // 会社番号テキストボックス
  const [commercialBookNumberPart1, setCommercialBookNumberPart1] =
    useState<string>("");
  const [commercialBookNumberPart2, setCommercialBookNumberPart2] =
    useState<string>("");
  const [commercialBookNumberPart3, setCommercialBookNumberPart3] =
    useState<string>("");

  // 市区町村セレクトボックスの内容
  const { city, setCity, cityList } = useCity(prefecture, "");

  // 商号・名称を選択した場合の検索条件と商業・名称フィールドの非活性化処理
  const [commercialBookTypeCompanyName, setCommercialBookTypeCompanyName] =
    useState<boolean>(true);

  // 会社法人等番号を選択した場合の検索条件と商業・名称フィールドの非活性化処理
  const [commercialBookTypeCompanyNumber, setCommercialBookTypeCompanyNumber] =
    useState<boolean>(false);

  // 前方一致チェックボックス
  const [startsWithMatch, setStartsWithMatch] = useState<boolean>(false);

  // バリデーションのためのフックを導入する
  const validator = useValidator({
    commercialBookTypeCompanyName,
    commercialBookTypeCompanyNumber,
  });

  const navigate = useNavigate();

  const { mypageTop } = useFeatureFlags();

  // 都道府県セレクトボックス変更時の処理
  const handlePrefecture = (event: SelectChangeEvent<string>): void => {
    setPrefecture(event.target.value);
    validator.pref.validate(event.target.value);
    // 市区町村selectBoxの中身が変わるのでselectBoxを初期状態に戻す
    setCity("");
  };

  // 市区町村セレクトボックス変更時の処理
  const handleCity = (event: SelectChangeEvent<string>): void => {
    setCity(event.target.value);
    validator.city.validate(event.target.value);
  };

  // 市区町村のセレクトボックスの選択項目を生成する関数
  const getCityMenuItems = (
    cityListResponse: CityListResponse
  ): React.ReactNode | undefined => {
    const cityList = cityListResponse.list;
    if (cityList.length > 0) {
      return cityList.map((value) => (
        <MenuItem key={value.id} value={value.cityCode}>
          {value.name}
        </MenuItem>
      ));
    }
  };

  // 市区町村コードから市区町村名に変換する
  // 変換できない場合はundefined
  const getCityNameByCityCode = (code: string): string | undefined => {
    return cityList.list.find((city) => city.cityCode === code)?.name;
  };

  // バリデーションチェックを行う関数
  const handleCheckValidation = (): void => {
    // すべての要素に対してバリデーションを実行する
    let results = [
      validator.pref.validate(prefecture),
      validator.city.validate(city),
    ];

    if (commercialBookTypeCompanyNumber) {
      // 商号・名称のバリデーションエラーをクリア
      validator.commercialBookName.clearError();
      // 会社法人等番号のバリデーションを実行する
      results = results.concat([
        validator.commercialBookNumberPart1.validate(commercialBookNumberPart1),
        validator.commercialBookNumberPart2.validate(commercialBookNumberPart2),
        validator.commercialBookNumberPart3.validate(commercialBookNumberPart3),
      ]);
    } else if (commercialBookTypeCompanyName) {
      // 会社法人等番号のバリデーションエラーをクリア
      validator.commercialBookNumberPart1.clearError();
      validator.commercialBookNumberPart2.clearError();
      validator.commercialBookNumberPart3.clearError();
      // 商号・名称のバリデーションを実行する
      results = results.concat([
        validator.commercialBookName.validate(commercialBookName),
      ]);
    }

    // resultsの全ての値がtrueならvalidation通過とみなす
    if (results.every((value) => value)) {
      handleNavigate();
    }
  };

  // 会社・法人一覧に遷移
  const handleNavigate = (): void => {
    const matchMethod = commercialBookTypeCompanyName
      ? "商号・名称"
      : "会社法人等番号";
    const matchType = startsWithMatch ? "前方一致" : "完全一致";
    navigate("/commercialbook/companylist", {
      replace: false,
      state: {
        matchMethod,
        matchType,
        closed: false,
        kubun: "商業及び法人",
        prefectureName: getPrefectureNameById(Number(prefecture)),
        location: getCityNameByCityCode(city),
        companyName: commercialBookTypeCompanyName
          ? commercialBookName
          : undefined,
        corporateNumber: commercialBookTypeCompanyName
          ? undefined
          : commercialBookNumberPart1 +
            commercialBookNumberPart2 +
            commercialBookNumberPart3,
      },
    });
  };

  // 検索方法の変更時の処理
  const handleSearchTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const isCompanyName = event.target.value === "companyName";
    setCommercialBookTypeCompanyName(isCompanyName);
    setCommercialBookTypeCompanyNumber(!isCompanyName);
  };

  // 完全一致/前方一致チェックボックスの変更時の処理
  const handleSearchConditionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const isExactMatch = event.target.value === "exactMatch";
    setStartsWithMatch(!isExactMatch);
  };

  return (
    <PagePaper>
      <form>
        <Box sx={{ display: "flex" }}>
          {/* ページタイトル */}
          <Box sx={{ display: "flex", mb: 3 }}>
            <PersonIcon sx={{ mr: 1 }} fontSize="large" />
            <PageTitle>商業登記取得</PageTitle>
          </Box>
          <Box sx={{ ml: 10 }}>
            <ul>
              <li>最新商業登記を取得いたします</li>
            </ul>
          </Box>
        </Box>
        <SectionPaper>
          {/* 検索方法 */}
          <Box sx={{ m: 2 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">検索方法</FormLabel>
              <RadioGroup
                row
                aria-label="search-method"
                name="search-method"
                value={
                  commercialBookTypeCompanyNumber
                    ? "companyNumber"
                    : "companyName"
                }
                onChange={handleSearchTypeChange}
              >
                <FormControlLabel
                  value="companyName"
                  control={<Radio />}
                  label="商号・名称"
                />
                <FormControlLabel
                  value="companyNumber"
                  control={<Radio />}
                  label="会社法人等番号"
                />
              </RadioGroup>
            </FormControl>

            {/* 都道府県、市区町村、区分 */}
            <Grid container spacing={2}>
              <Grid item>
                <Box sx={{ my: 1 }}>
                  <SizingWrapper>
                    <FormControl fullWidth error={validator.pref.state.error}>
                      <InputLabel id="select-label">都道府県</InputLabel>
                      <Select
                        labelId="select-label"
                        label="都道府県"
                        value={prefecture}
                        onChange={handlePrefecture}
                      >
                        {getPrefectureMenuItems()}
                      </Select>
                      <FormHelperText>
                        {validator.pref.state.helperTextWhenError}
                      </FormHelperText>
                    </FormControl>
                  </SizingWrapper>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ my: 1 }}>
                  <SizingWrapper>
                    <FormControl fullWidth error={validator.city.state.error}>
                      <InputLabel id="select-label">市区町村</InputLabel>
                      <Select
                        labelId="select-label"
                        label="市区町村"
                        value={city}
                        onChange={handleCity}
                      >
                        {getCityMenuItems(cityList)}
                      </Select>
                      <FormHelperText>
                        {validator.city.state.helperTextWhenError}
                      </FormHelperText>
                    </FormControl>
                  </SizingWrapper>
                </Box>
              </Grid>
            </Grid>

            {/* 検索条件 */}
            <Grid container spacing={2}>
              <Grid item>
                <Box sx={{ my: 2 }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">検索条件</FormLabel>
                    <RadioGroup
                      row
                      aria-label="search-condition"
                      name="search-condition"
                      value={startsWithMatch ? "startsWithMatch" : "exactMatch"}
                      onChange={handleSearchConditionChange}
                    >
                      <FormControlLabel
                        value="exactMatch"
                        control={<Radio />}
                        label="完全一致"
                        disabled={commercialBookTypeCompanyNumber}
                      />
                      <FormControlLabel
                        value="startsWithMatch"
                        control={<Radio />}
                        label="前方一致"
                        disabled={commercialBookTypeCompanyNumber}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: "error.main",
                    mt: 1,
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  会社法人種別を除いて入力してください。（例：株式会社
                  法務建設を検索する場合 法務建設）
                </Typography>
                <TextField
                  error={validator.commercialBookName.state.error}
                  label="商号・名称"
                  helperText={
                    validator.commercialBookName.state.helperTextWhenError
                  }
                  value={commercialBookName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setStateCommercialBookName(event.target.value);
                    validator.commercialBookName.validate(event.target.value);
                  }}
                  sx={{ width: "700px" }}
                  disabled={commercialBookTypeCompanyNumber}
                />
              </Grid>
            </Grid>

            {/* 会社番号 */}
            <Grid container spacing={2}>
              <Grid item>
                <Box sx={{ my: 2 }}>
                  <Label>会社法人等番号</Label>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <TextField
                  error={validator.commercialBookNumberPart1.state.error}
                  label="会社番号（4桁）"
                  helperText={
                    validator.commercialBookNumberPart1.state
                      .helperTextWhenError
                  }
                  value={commercialBookNumberPart1}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCommercialBookNumberPart1(event.target.value);
                    validator.commercialBookNumberPart1.validate(
                      event.target.value
                    );
                  }}
                  inputProps={{ maxLength: 4 }}
                  sx={{ width: "auto" }}
                  disabled={commercialBookTypeCompanyName}
                />
              </Grid>
              <Typography sx={{ margin: "auto 10px" }}>ー</Typography>
              <Grid item>
                <TextField
                  error={validator.commercialBookNumberPart2.state.error}
                  label="（2桁）"
                  helperText={
                    validator.commercialBookNumberPart2.state
                      .helperTextWhenError
                  }
                  value={commercialBookNumberPart2}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCommercialBookNumberPart2(event.target.value);
                    validator.commercialBookNumberPart2.validate(
                      event.target.value
                    );
                  }}
                  inputProps={{ maxLength: 2 }}
                  sx={{ width: "auto" }}
                  disabled={commercialBookTypeCompanyName}
                />
              </Grid>
              <Typography sx={{ margin: "auto 10px" }}>ー</Typography>
              <Grid item>
                <TextField
                  error={validator.commercialBookNumberPart3.state.error}
                  label="（6桁）"
                  helperText={
                    validator.commercialBookNumberPart3.state
                      .helperTextWhenError
                  }
                  value={commercialBookNumberPart3}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCommercialBookNumberPart3(event.target.value);
                    validator.commercialBookNumberPart3.validate(
                      event.target.value
                    );
                  }}
                  inputProps={{ maxLength: 6 }}
                  sx={{ width: "auto" }}
                  disabled={commercialBookTypeCompanyName}
                />
              </Grid>
              <Grid item sx={{ display: "flex" }}>
                <Typography
                  sx={{ color: "text.secondary", margin: "auto 10px" }}
                >
                  12桁(4桁-2桁-6桁)を入力してください
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* 取得ボタン */}
          <Box
            sx={{
              mr: 2,
              my: 3,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              label="検索"
              onClick={() => {
                handleCheckValidation();
              }}
              variant={ButtonVariantOption.Contained}
              size={SizingWrapperStyle.SMALL}
            />
          </Box>
        </SectionPaper>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link
            href={mypageTop ? "/mypage" : "/feed"}
            sx={{ display: "inline-block" }}
          >
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>
                {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>
    </PagePaper>
  );
};
