import { useAcquireBookForm } from "./hooks/useAcquireBookForm";
import { AcquireBookPresenter } from "./presenter";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getPrefectureIdByValue } from "@/utils/utilsAcquireBook";
import { useCity } from "@/hooks/useCity";

export const AcquireBookContainer: React.FC = () => {
  const { handleSubmit } = useAcquireBookForm();

  const [searchParams] = useSearchParams();

  const prefInitId =
    String(getPrefectureIdByValue(searchParams.get("prefecture") ?? "")) ?? "";

  // 都道府県セレクトボックス
  const [prefecture, setPrefecture] = useState<string>(prefInitId);

  // 市区町村セレクトボックスの内容
  const { city, setCity, cityList } = useCity(
    prefecture,
    searchParams.get("city") ?? ""
  );

  // 市町村以下テキストボックス
  const [locationWithoutCity, setLocationWithoutCity] = useState<string>(
    searchParams.get("location") ?? ""
  );

  // 地番または家屋番号テキストボックス
  const [chibanKaokuNumber, setChibanKaokuNumber] = useState<string>(
    searchParams.get("chiban") ?? ""
  );

  // 不動産種別セレクトボックス
  const [realEstateTypeEng, setRealEstateTypeEng] = useState<string>(
    searchParams.get("realEstate") ?? ""
  );

  // 登記/図面種別選択チェックボックス
  const [requestOptions, setRequestOptions] = useState<
    Record<string, Record<string, boolean>>
  >({
    syoyusya: { checked: false, disable: true },
    zennbu: { checked: false, disable: true },
    sintaku: { checked: false, disable: true },
    kyodo: { checked: false, disable: true },
    genzai: { checked: false, disable: true },
    tiseki: { checked: false, disable: true },
    densi: { checked: false, disable: true },
    tieki: { checked: false, disable: true },
    tatemono: { checked: false, disable: true },
  });

  return (
    <AcquireBookPresenter
      handleSubmit={handleSubmit}
      prefecture={prefecture}
      setPrefecture={setPrefecture}
      city={city}
      setCity={setCity}
      locationWithoutCity={locationWithoutCity}
      setLocationWithoutCity={setLocationWithoutCity}
      chibanKaokuNumber={chibanKaokuNumber}
      setChibanKaokuNumber={setChibanKaokuNumber}
      realEstateTypeEng={realEstateTypeEng}
      setRealEstateTypeEng={setRealEstateTypeEng}
      requestOptions={requestOptions}
      setRequestOptions={setRequestOptions}
      cityList={cityList}
    />
  );
};
