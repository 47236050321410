import { Button, ButtonVariantOption } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { useExcelExport } from "@/hooks/useExcelExport";
import { Divider, Stack } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import { convertTypeEnglishToJapanese } from "../utils";

export const CustomFooter: React.FC = () => {
  // エクセルエクスポートで必要な関数を取得。
  const { downloadAsExcelFile, getFeedGridData, makeExcelUint8Array } =
    useExcelExport();

  // feedGridの内部データを読み書きするためのハンドルみたいなものを取得
  const apiRef = useGridApiContext();

  /**
   * ピンのデータをエクセルでエクスポートする。
   */
  const handleExportExcel = (): void => {
    const arrayOfArray = getFeedGridData(apiRef);
    const formatData = arrayOfArray.map((element, index) => {
      // Action列は削除。
      element.pop();

      // ヘッダーの種別は日本語変換しない。
      if (index !== 0) element[0] = convertTypeEnglishToJapanese(element[0]);

      return element;
    });

    const data = makeExcelUint8Array(formatData, "地図検索");

    downloadAsExcelFile(data, "R.E.DATA_地図検索_ピンデータ");
  };

  return (
    <>
      <Divider />
      <Stack direction={"row"} justifyContent={"end"} m={1}>
        <Button
          label="エクセルエクスポート"
          onClick={handleExportExcel}
          size={SizingWrapperStyle.INHERIT}
          variant={ButtonVariantOption.Contained}
        />
      </Stack>
    </>
  );
};
