import { SearchBox } from "./components/SearchBox";
import React, { useEffect, useRef, useState } from "react";
import { PageTitle } from "@/components/Title";
import { PagePaper } from "@/components/Paper";
import {
  type GeoSpaceChiban,
  type MarkerState,
  type PropertySelectionRow,
  type ReceptionBook,
  type GeoSpaceReverseApiError,
  type KaokuNumberSearchChiban,
  type RestorePropertyRow,
} from "./types";
import { LeafletMap } from "./components/LeafletMap";
import { LatLng } from "leaflet";
import { toast } from "react-toastify";
import { useGeoSpaceApi } from "@/features/map/hooks/useGeospaceSearch";
import { NYHalfWidthNumberToFillWidthNumber, wgs2jgs } from "@/utils/utils";
import { PopupContent } from "@/features/map/components/PopupContent";
import Grid from "@mui/material/Grid";
import type {
  AcquireMultipleBookHandoffData,
  BookTypeEng,
} from "@/types/acquirebook";
import { useReceptionBookApi } from "@/features/map/hooks/useReceptionBook";
import {
  Button as MuiButton,
  Box,
  ButtonBase,
  Modal,
  Stack,
  type SxProps,
} from "@mui/material";
import { type LocationStateWithHandOff } from "@/types/handOffMessage";
import { PropertySelectionGrid } from "./components/PropertySelectionGrid";
import { Button, ButtonVariantOption } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { AcquireMultipleBooksModal } from "./components/AcquireMultipleBooksModal";
import { KaokuNumberSearchModal } from "./components/KaokuNumberSearchModal";
import { useAcquireBookForm } from "@/features/acquireMultipleBooks/hooks/useAcquireBookForm";
import { GetPrefecturesAPI } from "@/api";
import { type IPrefecture } from "@/types/prefectures";
import { useApiClient } from "@/hooks/useApiClient";
import { format } from "date-fns";
import {
  CreatePinHistoryAPI,
  type CreatePinHistoryResponse,
} from "@/features/map/api/CreatePinHistoryAPI";
import { GetLatLngByAddressAPI } from "@/features/map/api/getLatLngByAddress";

import price_map_button from "@/assets/ChikaMap_M.gif";
import { useFeatureFlags } from "@/configs/featureFlag";
import Tooltip from "@mui/material/Tooltip";
import { useMapServicePermission } from "@/hooks/useMapServicePermission";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { type GridSelectionModel } from "@mui/x-data-grid";
import { RestorePropertyModal } from "./components/RestorePropertyGridModal";
import { formatArea } from "./utils";

// 初期表示位置は東京駅
const initialMapLatLng = new LatLng(35.68124580227406, 139.76669536600596);

/**
 * IPopupBookContent
 * ポップアップの内容を表現するインターフェース
 * book: 受付帳データ
 * lat, lng: 緯度経度
 * jyuukyoHyouji: 住居表示の文字列
 */
interface IPopupBookContent {
  book?: ReceptionBook;
  lat: number;
  lng: number;
  jyuukyoHyouji?: string;
  _id?: number;
  chibanRawData?: GeoSpaceChiban;
}

/**
 * getPopupBookResult
 * getAllPopupBookContentsでAPIを呼び出した結果を表現するインターフェース
 */
interface getPopupBookResult {
  query: { book_id: number; address: string }; // 呼び出しクエリ
  search: GeoSpaceChiban | undefined; // search呼び出し結果
  book: ReceptionBook | undefined; // 受付帳データ呼び出し結果
}

/**
 * 文字列として渡されたyyyy-mm-ddからy年m月d日に変換する
 * @param d 日時(yyyy-mm-dd)
 * 成功した場合はy年m月d日の形式で返す
 * 失敗した場合は未設定を返す
 */
const formatDate = (d: string | undefined): string => {
  if (d) {
    const date = new Date(d);
    if (!Number.isNaN(date.getTime())) {
      return format(date, "yyyy年MM月dd日");
    } else {
      console.error("Dateオブジェクトが正しく生成できませんでした", d);
    }
  }
  return "未設定";
};

/**
 * 指定した座標の地価マップのURLを生成する
 * @param coordinates 座標（日本測地系であること）
 * @return 地価マップのURLを返す、座標がundefinedの場合は地価マップデフォルトの位置のURLを返す
 */
const generateChikaMapUrl = (coordinates?: LatLng): string => {
  const chikaMapBaseURL = "https://www.chikamap.jp/chikamap/Agreement";
  const chikaMapParams = new URLSearchParams();

  chikaMapParams.append("IsPost", "False");
  chikaMapParams.append("MapId", "323");

  if (coordinates) {
    chikaMapParams.append(
      "RequestPage",
      `/chikamap/Map?mid=323&mpx=${coordinates.lng}&mpy=${coordinates.lat}`
    );
  } else {
    chikaMapParams.append("RequestPage", "/chikamap/Map?mid=323");
  }
  return chikaMapBaseURL + "?" + chikaMapParams.toString();
};

/**
 * 指定した座標のGoogle Maps衛星写真のURLを生成する。
 * @param {LatLng | undefined} coordinates 座標（世界測地系であること）
 * @return {string} Google Mapの衛星写真のURLを返す、座標がundefinedの場合はGoogle Mapデフォルトの位置のURLを返す。
 */
const generateGoogleMapUrl = (coordinates?: LatLng): string => {
  const googleMapBaseURL = "https://www.google.com/maps/";
  const googleMapParams = new URLSearchParams();

  googleMapParams.append("api", "1");
  googleMapParams.append("map_action", "map");
  googleMapParams.append("basemap", "satellite");

  if (coordinates != null) {
    googleMapParams.append("center", `${coordinates.lat},${coordinates.lng}`);
    googleMapParams.append("zoom", "19");
  }

  return googleMapBaseURL + "@?" + googleMapParams.toString();
};

/**
 * ポップアップの内容を表示するReactコンポーネント
 * props: IPopupBookContent
 */
export const PopupBookContent: React.FC<IPopupBookContent> = (
  props: IPopupBookContent
) => {
  const { book, lat, lng, chibanRawData } = props;

  if (book) {
    const chiban = book.chiban === "" ? "地番記録なし" : book.chiban;

    return (
      <>
        <Box>
          <dl>
            <dt>登記原因</dt>
            <dd>{book.receptionReason ?? "未設定"}</dd>
            <dt>受付年月日</dt>
            <dd>{formatDate(book.legalAffairsBureauRequestDate)}</dd>
            <dt>所在</dt>
            <dd>{book.address ?? "未設定"}</dd>
            <dt>地番</dt>
            <dd>{chiban}</dd>
            <dt>緯度経度</dt>
            <dd>
              {lat ?? "未設定"}, {lng ?? "未設定"}
            </dd>
            <dt>推計地積(㎡)</dt>
            <dd>{formatArea(chibanRawData?.area ?? null)}</dd>
          </dl>
        </Box>
      </>
    );
  }
  return <>受付帳情報が取得できませんでした</>;
};

export const ChibanViewer: React.FC = () => {
  // ピン履歴情報を保存するAPI
  const createPinHistoryAPI = CreatePinHistoryAPI();
  // 住居表示から座標を取得するAPI
  const getLatLngByAddressAPI = GetLatLngByAddressAPI();

  // UI左側テキスト入力エリアのState
  const [normalAddressTextBox, setNormalAddressTextBox] = useState<string>("");
  const [chibanAddressTextBox, setChibanAddressTextBox] = useState<string>("");

  // 地図に表示するポリゴンのデータ
  // 地図クリック、住居表示検索、地番検索でピンを立てた地点のポリゴンデータ
  const [polygonData, setPolygonData] = useState<LatLng[]>([]);
  // 登記図面取得物件選択テーブルのポリゴンデータ
  const [selectedPropertiesPolygonData, setSelectedPropertiesPolygonData] =
    useState<LatLng[][]>([]);

  // 地図の初期表示座標
  const [viewLatLng, setViewLatLng] = useState<LatLng>(initialMapLatLng);

  // 地図上でクリックされた座標、クリックされた時に更新される
  const [clickedLatLng, setClickedLatLng] = useState<LatLng | null>(null);

  // 地図に表示するポップアップのデータをまとめているState
  const [markerState, setMarkerState] = useState<MarkerState>({
    latlng: initialMapLatLng,
    hide: false,
    content: null,
  });

  // 受付帳の情報を表示するポップアップのデータを保存するState
  const [receptionBookMarkerStates, setReceptionBookMarkerStates] = useState<
    MarkerState[]
  >([]);

  // 登記図面取得物件選択テーブルのState
  const [selectedProperties, setSelectedProperties] = useState<
    PropertySelectionRow[]
  >([]);

  // 登記図面取得モーダル用のState
  const [acquireMultipleBooksRows, setAcquireMultipleBooksRows] = useState<
    AcquireMultipleBookHandoffData[]
  >([]);

  // 登記図面取得モーダル表示制御用のState
  const [acquireMultipleBooksModalIsOpen, setAcquireMultipleBooksModalIsOpen] =
    useState<boolean>(false);

  // 都道府県リストのState
  const [prefectures, setPrefectures] = useState<IPrefecture[]>([]);

  // 受付帳ピンの読み込み状態を保存するState
  const [allReceptionBookMarkerLoaded, setAllReceptionBookMarkerLoaded] =
    useState<boolean>(false);

  // 受付帳ピン一時保存用のRef
  const receptionBookMarkerRef = useRef<MarkerState[]>([]);

  // 家屋番号検索モーダル表示制御用のState
  const [kaokuNumberSearchModalIsOpen, setKaokuNumberSearchModalIsOpen] =
    useState<boolean>(false);

  // 家屋番号検索モーダルに渡す地番リストのState
  const [kaokuNumberSearchChibanList, setKaokuNumberSearchChibanList] =
    useState<KaokuNumberSearchChiban[]>([]);

  // 「ピンを呼び出す」モーダル表示のState
  const [open, setOpen] = useState<boolean>(false);

  // 「ピンを呼び出す」モーダルの選択テーブルのState
  const [properties, setProperties] = useState<RestorePropertyRow[]>([]);

  // 「ピンを呼び出す」モーダルで選択されたデータのState
  const [selectedRowIds, setSelectedRowIds] = useState<GridSelectionModel>([]);

  // 「ピンを取り出す」モーダルの選択テーブルのid計算用State。
  // TODO: ピン履歴のデータ保存の仕方によっては不要となる？
  const [propertyRowCount, setPropertyRowCount] = useState<number>(0);

  // custom hooks 呼び出し
  const { apiClient } = useApiClient();
  const { handleSubmit: acquireBookFormHandleSubmit } = useAcquireBookForm();

  const {
    priceMap,
    kaokuNumberSearch: kaokuNumberSearchFlag,
    mapPropertyListHistory,
  } = useFeatureFlags();

  /**
   * savePinHistory 受付帳情報を保存する
   * @param selectedItem 受付帳IDのリスト
   */
  const savePinHistory = async (
    selectedItem: AcquireMultipleBookHandoffData[]
  ): Promise<CreatePinHistoryResponse | undefined> => {
    const bookIDs = selectedItem
      .map((value) => value.bookId)
      .filter((id): id is number => id !== undefined);
    const response = await createPinHistoryAPI.trigger(bookIDs);
    if (response) {
      return response;
    } else {
      console.error("savePinHistory error");
      return response;
    }
  };

  /**
   * getAllPopupBookContents
   * feed画面から受け取った配列から、ポップアップに表示するIPopupBookContent型の配列にして返す
   * selectedItemに含まれるbookIdから受付帳の詳細をバックエンドから取得する
   * prefName,locationFull,chibanKaokuNumberを用いて住居表示をバックエンドから取得する
   *
   * return: getPopupBookResult[] API呼び出しクエリとその結果のリスト
   */
  const getAllPopupBookContents = async (
    selectedItem: AcquireMultipleBookHandoffData[],
    onSuccess: (content: IPopupBookContent) => void,
    onFail: (
      item: AcquireMultipleBookHandoffData,
      searchResult: GeoSpaceChiban | undefined,
      bookResult: ReceptionBook | undefined
    ) => void
  ): Promise<getPopupBookResult[]> => {
    return await Promise.all(
      selectedItem.map(async (item): Promise<getPopupBookResult> => {
        const query = `${item.prefName}${item.locationFull}${item.chibanKaokuNumber}`;
        // バックエンドAPI呼び出し、ここで住居表示と、緯度経度を持ってくる
        const search = geospaceSearch(query);
        // バックエンドAPI呼び出し、ここで受付帳データを持ってくる
        if (item.bookId === undefined) {
          onFail(item, undefined, undefined);
          return {
            query: { book_id: item.id, address: query },
            search: undefined,
            book: undefined,
          };
        }
        const book = getReceptionBook(item.bookId);

        // search,bookのAPIレスポンスを待つ
        const [searchResult, bookResult] = await Promise.all([search, book]);

        if (searchResult && bookResult) {
          // 住居表示、緯度経度、受付帳データ全て揃った
          const jyuukyoHyouji = `${searchResult.prefName}${
            searchResult.cityName
          }${searchResult.ooazaName ?? ""}${searchResult.azaName ?? ""}${
            searchResult.chiban
          }`;
          onSuccess({
            lat: searchResult.lat,
            lng: searchResult.lng,
            book: bookResult,
            jyuukyoHyouji,
            _id: item.id,
            chibanRawData: searchResult,
          });
        } else if (searchResult) {
          // 住居表示と、緯度経度は揃ったが受付帳データの取得に失敗した
          const jyuukyoHyouji = `${searchResult.prefName}${
            searchResult.cityName
          }${searchResult.ooazaName ?? ""}${searchResult.azaName ?? ""}${
            searchResult.chiban
          }`;
          onSuccess({
            lat: searchResult.lat,
            lng: searchResult.lng,
            jyuukyoHyouji,
            _id: item.id,
            chibanRawData: searchResult,
          });
        } else {
          onFail(item, searchResult, bookResult);
        }
        // API呼び出し時のクエリと呼び出し結果をまとめて返す
        return {
          query: { book_id: item.id, address: query },
          search: searchResult,
          book: bookResult,
        };
      })
    );
  };

  // 地図表示権限の判定
  const [hasMapViewingPermission, loading] = useMapServicePermission();

  useEffect(() => {
    (async () => {
      // 都道府県リストをAPIから取得する
      const prefectures = await GetPrefecturesAPI(apiClient);
      if (prefectures.length !== 0) {
        setPrefectures(prefectures);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // URLからstateKeyを取得
      const urlParams = new URLSearchParams(window.location.search);
      const stateKey = urlParams.get("stateKey");
      if (stateKey) {
        const state = JSON.parse(
          localStorage.getItem(stateKey) ?? "{}"
        ) as LocationStateWithHandOff;

        // 不動産種別が土地以外のデータが含まれている場合、エラーメッセージを表示
        const argument =
          (state?.handOffMessage
            ?.argument as AcquireMultipleBookHandoffData[]) ?? [];
        if (argument.some((item) => item.bookType !== "LAND")) {
          toast.error("不動産種別が土地以外のデータは地図に反映されません");
        }

        localStorage.removeItem(stateKey); // 使用後に削除
        // handOffMessageが存在するか
        if (state?.handOffMessage) {
          const handOffMessage = state.handOffMessage;
          // handOffMessageのcommandが"MAP_DRAW_RECEPTION_BOOKS"であるか
          if (handOffMessage.command === "MAP_DRAW_RECEPTION_BOOKS") {
            // handOffMessageから選択した受付帳の"土地"データのみを取り出す
            const selectedItem = (
              (handOffMessage?.argument as AcquireMultipleBookHandoffData[]) ??
              []
            ).filter((item) => item.bookType === "LAND");

            // 保存API呼び出し
            void savePinHistory(selectedItem);

            // 全データ取得
            await getAllPopupBookContents(
              selectedItem,
              (content) => {
                // データ取得成功時の処理
                // console.log("API取得結果 onSuccess", content);
                const marker: MarkerState = {
                  latlng: new LatLng(content.lat, content.lng),
                  content: (
                    <PopupBookContent
                      book={content.book}
                      lat={content.lat}
                      lng={content.lng}
                      jyuukyoHyouji={content.jyuukyoHyouji}
                      chibanRawData={content.chibanRawData}
                    />
                  ),
                  _id: content._id,
                  book: content.book,
                  chibanRawData: content.chibanRawData,
                };
                receptionBookMarkerRef.current = [
                  marker,
                  ...receptionBookMarkerRef.current,
                ];
                // console.log(
                //   "Leafletに渡すピンの座標＋ポップアップ内容データ",
                //   receptionBookMarkerRef.current
                // );
                setReceptionBookMarkerStates(receptionBookMarkerRef.current);
              },
              (item, searchResult, bookResult) => {
                // データ取得失敗時の処理
                // geospaceReverse()が失敗した場合、座標がわからずピンを立てることができない
                if (searchResult === undefined) {
                  const query = `${item.prefName}${item.locationFull}${item.chibanKaokuNumber}`;
                  console.error(
                    `API失敗 geospaceSearch(${query}) returned undefined`
                  );
                }
                if (bookResult === undefined) {
                  console.error(
                    `API失敗 getReceptionBook(${
                      item.bookId ?? "undefined"
                    }) returned undefined`
                  );
                }
              }
            );

            setAllReceptionBookMarkerLoaded(true);

            // 結果集計
            // const allCount = result.length;
            // const searchSuccessCount = result.filter(
            //   (item) => item.search
            // ).length;
            // const bookSuccessCount = result.filter((item) => item.book).length;
            // const searchFailCount = result.filter(
            //   (item) => item.search === undefined
            // ).length;
            // const bookFailCount = result.filter(
            //   (item) => item.book === undefined
            // ).length;
            // const searchFailQueries = result
            //   .filter((item) => item.search === undefined)
            //   .map((item) => item.query);
            //
            // console.log(`総数:${allCount}`);
            // console.log(
            //   `GeoSpaceReverse成功数:${searchSuccessCount} 登記データ取得成功数:${bookSuccessCount}`
            // );
            // console.log(
            //   `GeoSpaceReverse失敗数:${searchFailCount} 登記データ取得失敗数:${bookFailCount}`
            // );
            // console.log("GeoSpaceReverse失敗データ一覧", searchFailQueries);
          }
        }
      }
    })();
  }, []);

  const {
    geospaceSearch,
    geospaceReverse,
    geospaceReverseFree,
    geospaceReverseByLatLng,
  } = useGeoSpaceApi();
  const { getReceptionBook } = useReceptionBookApi();

  /**
   * 住所検索、地番検索が失敗した場合に行われる処理
   * この場合、入力された住所付近に地図を移動し、住居表示テキストボックスをGoogleMapAPIで得られた住所で更新する
   * @param address 検索する住所
   */
  const whenSearchFail = async (address: string): Promise<void> => {
    toast.info("地番データが存在しませんでした");

    // 住所の検索がエラーとなった場合、入力されていた住所の付近まで地図を移動させる
    const request = { address };
    const result = await getLatLngByAddressAPI.trigger(request);
    // 結果が見つからない場合はundefinedとなり、何もしない
    if (result) {
      // 地図を移動し、住所入力欄にGoogle Map APIによって得られた住所を設定する
      setViewLatLng(new LatLng(result[0], result[1]));
      setNormalAddressTextBox(result[2]);
    }
  };

  /**
   * 登記地番の検索処理
   */
  const handleChangeSearchTerm = async (): Promise<void> => {
    if (chibanAddressTextBox.trim() === "") {
      toast.error("登記地番を入力してください");
      return;
    }
    const r = await geospaceSearch(chibanAddressTextBox);
    if (r?.address) {
      const latlng = new LatLng(r.lat, r.lng);
      // 地図の表示位置をAPI呼び出し結果の座標に移動する
      setViewLatLng(latlng);
      // API呼び出し結果のポリゴンを表示する
      setPolygonData((r.polygon?.coordinates as LatLng[]) ?? []);
      // ポップアップの状態を設定する
      setMarkerState({
        latlng,
        hide: false,
        content: (
          <PopupContent
            addressChiban={r.addressChiban}
            address={r.address}
            prefName={r.prefName}
            cityName={r.cityName}
            ooazaName={r.ooazaName ?? ""}
            azaName={r.azaName ?? ""}
            chiban={r.chiban}
            handleKaokuNumberSearch={handleKaokuNumberSearch}
            kaokuNumberSearchFlag={kaokuNumberSearchFlag as boolean}
            area={r.area}
          />
        ),
      });
      // 左のUIの住居表示をAPI呼び出した結果の住居表示で更新
      setNormalAddressTextBox(NYHalfWidthNumberToFillWidthNumber(r.address));
    } else {
      await whenSearchFail(chibanAddressTextBox);
    }
  };

  /**
   * 住居表示、無料プランの検索処理
   */
  const handleChangeReverseSearchTermFree = async (): Promise<void> => {
    if (normalAddressTextBox.trim() === "") {
      toast.error("住居表示を入力してください");
      return;
    }
    try {
      const r = await geospaceReverseFree(normalAddressTextBox);
      if (r) {
        const latlng = new LatLng(r.lat, r.lng);

        // 地図の表示位置をAPI呼び出し結果の座標に移動する
        setViewLatLng(latlng);
        // ポップアップの状態を設定する
        setMarkerState({
          latlng,
          hide: false,
          content: (
            <PopupContent
              addressChiban={"地番は有料プランで表示されます"}
              address={normalAddressTextBox}
              prefName={""}
              cityName={""}
              ooazaName={""}
              azaName={""}
              chiban={""}
              handleKaokuNumberSearch={undefined}
              kaokuNumberSearchFlag={kaokuNumberSearchFlag as boolean}
              area={null}
            />
          ),
        });
      } else {
        toast.info("データが存在しませんでした");
      }
    } catch (error) {
      if ((error as GeoSpaceReverseApiError).response?.status === 400) {
        toast.error(
          "データが見つかりませんでした。入力内容を確認してください。"
        );
      } else {
        toast.error(
          "エラーが発生しました。しばらく経ってから再度お試しください。"
        );
      }
    }
  };

  /**
   * 住居表示の検索処理
   */
  const handleChangeReverseSearchTerm = async (): Promise<void> => {
    if (normalAddressTextBox.trim() === "") {
      toast.error("住居表示を入力してください");
      return;
    }
    try {
      const r = await geospaceReverse(normalAddressTextBox);
      if (r && !!r.polygon) {
        const latlng = new LatLng(r.lat, r.lng);
        // 地図の表示位置をAPI呼び出し結果の座標に移動する
        setViewLatLng(latlng);
        // API呼び出し結果のポリゴンを表示する
        setPolygonData((r.polygon?.coordinates as LatLng[]) ?? []);
        // ポップアップの状態を設定する
        setMarkerState({
          latlng,
          hide: false,
          content: (
            <PopupContent
              addressChiban={r.addressChiban}
              address={normalAddressTextBox}
              prefName={r.prefName}
              cityName={r.cityName}
              ooazaName={r.ooazaName ?? ""}
              azaName={r.azaName ?? ""}
              chiban={r.chiban}
              handleKaokuNumberSearch={handleKaokuNumberSearch}
              kaokuNumberSearchFlag={kaokuNumberSearchFlag as boolean}
              area={r.area}
            />
          ),
        });
        // 左のUIの地番をAPI呼び出した結果の地番で更新
        setChibanAddressTextBox(
          NYHalfWidthNumberToFillWidthNumber(r.addressChiban)
        );
      } else {
        toast.info("データが存在しませんでした");
        await whenSearchFail(normalAddressTextBox);
      }
    } catch (error) {
      if ((error as GeoSpaceReverseApiError).response?.status === 400) {
        toast.error(
          "データが見つかりませんでした。入力内容を確認してください。"
        );
      } else {
        toast.error(
          "エラーが発生しました。しばらく経ってから再度お試しください。"
        );
      }
      await whenSearchFail(normalAddressTextBox);
    }
  };

  // Map側で地図がクリックされ、clickedLatLngが更新されたときに実行する
  // APIを呼び出して、LatLng座標から地番情報を取得する
  useEffect(() => {
    if (clickedLatLng === null) return;

    // 権限なしの場合は何もしない
    if (!hasMapViewingPermission) return;

    (async () => {
      // REVIEW: undefinedの時の振る舞いは想定通りか？仮のnumberを入れなくてもいいのか？
      const r = await geospaceReverseByLatLng(
        clickedLatLng?.lat,
        clickedLatLng?.lng
      );
      if (r?.polygon) {
        // ポリゴンを設定
        setPolygonData((r.polygon.coordinates as LatLng[]) ?? []);
        // 地図のクリック場所の情報をUI左側のテキストボックスに反映する
        setNormalAddressTextBox(NYHalfWidthNumberToFillWidthNumber(r.address));
        setChibanAddressTextBox(
          NYHalfWidthNumberToFillWidthNumber(r.addressChiban)
        );
        // ポップアップ要素に必要な情報を設定する
        setMarkerState((prevState) => {
          return {
            ...prevState,
            content: (
              <PopupContent
                addressChiban={r.addressChiban}
                address={r.address}
                prefName={r.prefName}
                cityName={r.cityName}
                ooazaName={r.ooazaName ?? ""}
                azaName={r.azaName ?? ""}
                chiban={r.chiban}
                handleKaokuNumberSearch={handleKaokuNumberSearch}
                kaokuNumberSearchFlag={kaokuNumberSearchFlag as boolean}
                area={r.area}
              />
            ),
          };
        });
        // 登記取得物件選択テーブルに追加
        setSelectedProperties((prev) => {
          const bookType = "LAND" as BookTypeEng;
          // 重複チェック
          if (
            checkDuplicateProperties(
              prev,
              bookType,
              r.prefName,
              buildLocationString(r),
              r.chiban
            )
          ) {
            return prev;
          }

          const lastId: number = prev.length > 0 ? prev[prev.length - 1].id : 0;
          return [
            ...prev,
            {
              id: lastId + 1,
              selected: true,
              bookType,
              prefName: r.prefName,
              location: buildLocationString(r),
              chibanKaokuNumber: r.chiban,
              isFeedOrigin: false,
              rawData: r,
            },
          ].sort((a, b) => a.id - b.id);
        });
      } else {
        setPolygonData([]);
        setMarkerState((prevState) => {
          return {
            ...prevState,
            content: null,
          };
        });
        toast.info("データが存在しませんでした");
      }
    })();
  }, [clickedLatLng]);

  // 登記取得物件選択テーブルのデータが変更されたときに実行する
  // 種別が土地であり、ポリゴンデータがあるものを表示用stateに追加する
  useEffect(() => {
    const newPolygons = selectedProperties.map((property) => {
      if (
        property.bookType === "LAND" &&
        property.rawData &&
        property.rawData.polygon
      ) {
        return property.rawData.polygon.coordinates as LatLng[];
      } else {
        return [];
      }
    });

    setSelectedPropertiesPolygonData(newPolygons);
  }, [selectedProperties]);

  const buildLocationString = (r: GeoSpaceChiban): string => {
    const ooazaName = r.ooazaName ?? "";
    const azaName = r.azaName ?? "";
    return `${r.cityName}${ooazaName}${azaName}`;
  };

  const checkDuplicateProperties = (
    rows: PropertySelectionRow[],
    bookType: BookTypeEng,
    prefName: string,
    location: string,
    chiban: string
  ): boolean => {
    const key = `${bookType}${prefName}${location}${chiban}`;
    return rows.some(
      (row) =>
        `${row.bookType}${row.prefName}${row.location}${row.chibanKaokuNumber}` ===
        key
    );
  };

  const handleClickAcquireButton = (): void => {
    const selected = selectedProperties.filter((row) => row.selected);
    if (selected.length === 0) {
      toast.info("取得対象が選択されていません");
      return;
    }

    if (prefectures.length === 0) {
      toast.info("都道府県データが取得できませんでした");
      return;
    }

    setAcquireMultipleBooksRows(
      selected.map((row) => {
        const pref = prefectures.find((p) => p.name === row.prefName);
        return {
          id: row.id,
          bookType: row.bookType,
          prefCode: pref?.prefCode ?? "13",
          prefName: row.prefName,
          locationFull: row.location,
          chibanKaokuNumber: row.chibanKaokuNumber,
          kyoutan: false,
          sintaku: false,
          genzai: false,
          ownerInfo: false,
          electricDrawings: false,
          tisekiDrawings: false,
          tiekiDrawings: false,
          buildingDrawings: false,
        };
      })
    );

    setAcquireMultipleBooksModalIsOpen(true);
  };

  // RealEstateTypeのIDを登記図面取得用のBookTypeEngに変換する
  // 建物、土地、区分建物、一棟のみが登記図面取得の対象となる
  const convertRealEstateTypeIdToBookType = (
    realEstateTypeId: number
  ): BookTypeEng | "NOT_TARGET" => {
    switch (realEstateTypeId) {
      case 1:
        return "BUILDING"; // 建物
      case 2:
        return "LAND"; // 土地
      case 3:
        return "CONDOMINIUM"; // 区分建物
      case 6:
        return "ONE_BUILDING"; // 一棟
      default:
        return "NOT_TARGET";
    }
  };

  const handleClickImportReceptionBookMarkers = (): void => {
    // 保存されているピン情報を取得
    const receptionBookMarkers = receptionBookMarkerRef.current;

    // 変換処理
    let lastId =
      selectedProperties.length > 0
        ? [...selectedProperties].sort((a, b) => b.id - a.id)[0].id
        : 0;
    const rows: PropertySelectionRow[] = receptionBookMarkers
      .filter(
        (marker) =>
          marker.chibanRawData &&
          marker.book &&
          convertRealEstateTypeIdToBookType(marker.book.realEstateType) !==
            "NOT_TARGET"
      )
      .map((marker) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const chiban = marker.chibanRawData!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const book = marker.book!;
        return {
          id: ++lastId,
          selected: true,
          bookType: convertRealEstateTypeIdToBookType(
            book?.realEstateType
          ) as BookTypeEng,
          prefName: chiban.prefName,
          location: buildLocationString(chiban),
          chibanKaokuNumber: chiban.chiban,
          isFeedOrigin: true,
          rawData: chiban,
        };
      });

    // 重複をチェックしつつ追加
    const newRows = [...selectedProperties];
    rows.forEach((row) => {
      if (
        !checkDuplicateProperties(
          newRows,
          row.bookType,
          row.prefName,
          row.location,
          row.chibanKaokuNumber
        )
      ) {
        newRows.push(row);
      }
    });

    setSelectedProperties(newRows);
  };

  const handleKaokuNumberSearch = (
    chibanList: KaokuNumberSearchChiban[]
  ): void => {
    setKaokuNumberSearchChibanList(chibanList);
    setKaokuNumberSearchModalIsOpen(true);
  };

  const hasLand = selectedProperties.some(
    (property) => property.bookType === "LAND"
  );

  const handleClickKaokuNumberSearchFromSelectedProperties = (): void => {
    const lands = selectedProperties.filter(
      (property) => property.bookType === "LAND"
    );
    if (lands.length === 0) {
      toast.info("土地が選択されていません");
      return;
    }

    const chibanList = lands.map((land) => {
      return {
        prefectureName: land.prefName,
        location: land.location,
        chiban: land.chibanKaokuNumber,
      };
    });

    setKaokuNumberSearchChibanList(chibanList);
    setKaokuNumberSearchModalIsOpen(true);
  };

  // 物件リストに物件を追加する処理
  // 重複チェックを行い、重複していない場合のみ追加する
  const appendPropertySelectionRows = (rows: PropertySelectionRow[]): void => {
    setSelectedProperties((prev) => {
      const lastId: number = prev.length > 0 ? prev[prev.length - 1].id : 0;
      let newId = lastId + 1;

      // rows内の重複をフィルタリング
      const uniqueRows = rows.filter((row, index, self) => {
        return (
          index ===
          self.findIndex(
            (r) =>
              r.bookType === row.bookType &&
              r.prefName === row.prefName &&
              r.location === row.location &&
              r.chibanKaokuNumber === row.chibanKaokuNumber
          )
        );
      });

      // selectedProperties内の重複をフィルタリング
      const newRows = uniqueRows.filter((row) => {
        return !checkDuplicateProperties(
          prev,
          row.bookType,
          row.prefName,
          row.location,
          row.chibanKaokuNumber
        );
      });

      const rowsToAdd = newRows.map((row) => {
        return {
          ...row,
          id: newId++,
          selected: true,
        };
      });

      return [...prev, ...rowsToAdd].sort((a, b) => a.id - b.id);
    });
  };

  /**
   * 背景をグレーにするCSSを返す関数
   * @param disabled グレーアウトするかしないかを決めるフラグ
   * @return disabled===true: グレーアウトされたSXを返す、disabled===false: グレーアウト無しのSXを返す
   */
  const getGrayOutSx = (disabled: boolean): SxProps => {
    if (disabled) {
      return {
        "pointer-events": "none",
        opacity: "0.5",
        backgroundColor: "lightgray",
      };
    } else {
      return {
        "pointer-events": "auto",
        opacity: "1",
        backgroundColor: "inherit",
      };
    }
  };

  /**
   * ピンを履歴保存する。
   * 実際は画面左側の物件リストに表示されている項目を履歴として保存する。
   * TODO: Stateにピン履歴を保存しているが、実際はDBなどに保存？
   */
  const handleSavePropertyHistory = (): void => {
    const now = new Date();
    const rowId = propertyRowCount + 1;
    setPropertyRowCount(rowId);

    setProperties([
      ...properties,
      {
        id: rowId,
        selected: false,
        count: selectedProperties.length,
        recordName: now.toLocaleString() + "保存分",
        saveDatetime: now.toLocaleString(),
        properties: selectedProperties,
      },
    ]);

    toast.success("ピンを履歴保存しました。");
  };

  return (
    <>
      <PagePaper>
        <PageTitle>地図検索</PageTitle>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5} md={4}>
            <Grid item>
              <SearchBox
                label="住居表示"
                disabled={false}
                // 権限によって呼び出し先のAPI振り分ける
                handleApiRequest={
                  loading || (hasMapViewingPermission ?? false)
                    ? handleChangeReverseSearchTerm
                    : handleChangeReverseSearchTermFree
                }
                searchTerm={normalAddressTextBox}
                setSearchTerm={(value) => {
                  const result = NYHalfWidthNumberToFillWidthNumber(value);
                  setNormalAddressTextBox(result);
                }}
              />
            </Grid>
            <Grid item>
              <SearchBox
                label="登記地番"
                disabled={!(loading || (hasMapViewingPermission ?? false))}
                handleApiRequest={handleChangeSearchTerm}
                searchTerm={chibanAddressTextBox}
                setSearchTerm={(value) => {
                  const result = NYHalfWidthNumberToFillWidthNumber(value);
                  setChibanAddressTextBox(result);
                }}
              />
            </Grid>
            <Box
              sx={getGrayOutSx(
                !(loading || (hasMapViewingPermission ?? false))
              )}
            >
              <Stack
                direction={"column"}
                spacing={2}
                justifyContent={"center"}
                marginTop={4}
                height={"50%"}
              >
                <Stack direction={"row"} spacing={1}>
                  <Button
                    size={SizingWrapperStyle.INHERIT}
                    label="登記取得する"
                    variant={ButtonVariantOption.Contained}
                    onClick={() => {
                      handleClickAcquireButton();
                    }}
                  ></Button>
                  <Button
                    disabled={
                      receptionBookMarkerRef.current.length === 0 ||
                      !allReceptionBookMarkerLoaded
                    }
                    size={SizingWrapperStyle.INHERIT}
                    label="ピンを取り込む"
                    variant={ButtonVariantOption.Contained}
                    onClick={() => {
                      handleClickImportReceptionBookMarkers();
                    }}
                  ></Button>
                  {kaokuNumberSearchFlag ? (
                    <Button
                      size={SizingWrapperStyle.INHERIT}
                      label="家屋番号検索"
                      variant={ButtonVariantOption.Contained}
                      onClick={
                        handleClickKaokuNumberSearchFromSelectedProperties
                      }
                      disabled={!hasLand}
                    ></Button>
                  ) : null}
                  {mapPropertyListHistory && (
                    <Button
                      size={SizingWrapperStyle.INHERIT}
                      label="ピンを履歴保存する"
                      variant={ButtonVariantOption.Contained}
                      onClick={handleSavePropertyHistory}
                      disabled={!selectedProperties.length}
                    />
                  )}
                </Stack>
                <PropertySelectionGrid
                  rows={selectedProperties}
                  setRows={setSelectedProperties}
                ></PropertySelectionGrid>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} md={8}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Stack direction={"row"}>
                  {priceMap ? (
                    <Box mx={1} mb={1}>
                      <Tooltip
                        title={
                          "地図上の任意の地点をクリックしたあと、このボタンをクリックすると地価マップが開きます"
                        }
                        placement={"top"}
                      >
                        <ButtonBase
                          onClick={() => {
                            let url: string;
                            if (clickedLatLng) {
                              url = generateChikaMapUrl(wgs2jgs(clickedLatLng));
                            } else {
                              url = generateChikaMapUrl();
                            }
                            window.open(url, "_blank");
                          }}
                        >
                          <img
                            src={price_map_button}
                            alt={"地価マップボタン"}
                          />
                        </ButtonBase>
                      </Tooltip>
                    </Box>
                  ) : null}
                  <Box mx={1} mb={1}>
                    <Tooltip
                      title={
                        "地図上の任意の地点をクリックしたあと、このボタンをクリックするとその地点のGoogleMap（衛星写真）が開きます"
                      }
                      placement={"top"}
                    >
                      <MuiButton
                        onClick={() => {
                          const url = clickedLatLng
                            ? generateGoogleMapUrl(clickedLatLng)
                            : generateGoogleMapUrl();

                          window.open(url, "_blank");
                        }}
                        startIcon={<FmdGoodIcon />}
                        sx={{ textTransform: "none" }}
                        variant="contained"
                      >
                        Google Maps（衛星写真）
                      </MuiButton>
                    </Tooltip>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign={"right"}>
                  {!(loading || (hasMapViewingPermission ?? false)) ? (
                    <MuiButton
                      variant={"outlined"}
                      href={
                        "https://docs.google.com/forms/d/e/1FAIpQLSc55YOK76TfgQyXEYUoXQS6eZIC25V2qwKdILOMxV87fBCdIQ/viewform?usp=pp_url&entry.1890122079=%E5%9C%B0%E5%9B%B3%E6%A4%9C%E7%B4%A2%E3%81%AE%E6%9C%89%E5%84%9F%E3%83%97%E3%83%A9%E3%83%B3%E3%81%AB%E8%88%88%E5%91%B3%E3%81%82%E3%82%8A%E2%80%BB%E6%96%87%E8%A8%80%E3%81%AF%E7%B7%A8%E9%9B%86%E3%81%97%E3%81%AA%E3%81%84%E3%81%A7%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84"
                      }
                      sx={{ mr: 1 }}
                    >
                      有料プランの契約はこちら
                    </MuiButton>
                  ) : null}
                  {mapPropertyListHistory && (
                    <RestorePropertyModal
                      open={open}
                      properties={properties}
                      selectedRowIds={selectedRowIds}
                      setOpen={setOpen}
                      setProperties={setProperties}
                      setSelectedProperties={setSelectedProperties}
                      setSelectedRowIds={setSelectedRowIds}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
            <LeafletMap
              clickedAreaPolygonData={polygonData}
              polygonData={selectedPropertiesPolygonData}
              viewLatLng={viewLatLng}
              setClickedLatLng={setClickedLatLng}
              markerState={markerState}
              receptionBookMarkerStates={receptionBookMarkerStates}
            />
          </Grid>
        </Grid>
        <Modal
          open={acquireMultipleBooksModalIsOpen}
          aria-labelledby={"acquire-multiple-books-modal"}
          aria-describedby={"acquire-multiple-books-modal"}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              outline: "none",
            }}
            onClick={() => {
              setAcquireMultipleBooksModalIsOpen(false);
            }}
          >
            <Box
              sx={{
                width: 1600,
                maxWidth: "90%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 2,
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <AcquireMultipleBooksModal
                handleSubmit={acquireBookFormHandleSubmit}
                rows={acquireMultipleBooksRows}
              />
            </Box>
          </Box>
        </Modal>
        <Modal
          open={kaokuNumberSearchModalIsOpen}
          aria-labelledby={"kaoku-number-search-modal"}
          aria-describedby={"kaoku-number-search-modal"}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              outline: "none",
            }}
          >
            <Box
              sx={{
                width: 1600,
                maxWidth: "90%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 2,
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <KaokuNumberSearchModal
                chibanList={kaokuNumberSearchChibanList}
                setKaokuNumberSearchModalIsOpen={
                  setKaokuNumberSearchModalIsOpen
                }
                appendPropertySelectionRows={appendPropertySelectionRows}
              />
            </Box>
          </Box>
        </Modal>
      </PagePaper>
    </>
  );
};
