import React from "react";
import { Box, FormControl, FormLabel, Typography, Grid } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import { NYHalfWidthNumberToFillWidthNumber } from "@/utils/utils";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import { Button, ButtonVariantOption } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { type KaokuNumberSearchChiban } from "../types";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import { formatArea } from "../utils";

interface IPopupContent {
  addressChiban: string;
  address: string;
  prefName: string;
  cityName: string;
  ooazaName: string;
  azaName: string;
  chiban: string;
  area: number | null;
  handleKaokuNumberSearch?: (chibanList: KaokuNumberSearchChiban[]) => void;
  kaokuNumberSearchFlag: boolean;
}

/**
 * 地図上に表示するポップアップ画面の内容を表すReactコンポーネント
 * @param props addressChiban 地番として表示する文字列
 * @param props address 住居表示として表示する文字列
 * @param props prefName 登記取得画面に渡す文字列
 * @param props cityName 登記取得画面に渡す文字列
 * @param props ooazaName 登記取得画面に渡す文字列
 * @param props azaName 登記取得画面に渡す文字列
 * @param props chiban 登記取得画面に渡す文字列
 * @param props area 推計地積表示のために渡す数値
 */
export const PopupContent: React.FC<IPopupContent> = (props) => {
  const {
    addressChiban,
    address,
    prefName,
    cityName,
    ooazaName,
    azaName,
    chiban,
    handleKaokuNumberSearch,
    kaokuNumberSearchFlag = false,
    area,
  }: IPopupContent = props;
  const buttonState: boolean =
    prefName !== "" &&
    cityName !== "" &&
    /*
    大字、字については存在しない場合もあるので空文字でもボタンは有効にする
    ooazaName !== "" &&
    azaName !== "" &&
    */
    chiban !== "";

  const buildLocationString = (
    cityName: string,
    ooazaName: string | null,
    azaName: string | null
  ): string => {
    return `${cityName}${ooazaName ?? ""}${azaName ?? ""}`;
  };

  const handleClickKaokuNumberSearchButton = (): void => {
    if (handleKaokuNumberSearch) {
      const c: KaokuNumberSearchChiban = {
        prefectureName: prefName,
        location: buildLocationString(cityName, ooazaName, azaName),
        chiban,
      };
      handleKaokuNumberSearch([c]);
    }
  };

  return (
    <Box sx={{ width: 300 }}>
      <FormControl>
        <FormLabel>
          <PlaceIcon fontSize={"inherit"} />
          住居表示
        </FormLabel>
        <Typography
          ml={2}
          my={1}
          fontSize={"0.8rem"}
          variant={"body1"}
          component={"span"}
        >
          {NYHalfWidthNumberToFillWidthNumber(address)}
        </Typography>
        <FormLabel>
          <ImportContactsIcon fontSize={"inherit"} /> 地番
        </FormLabel>
        <Typography
          ml={2}
          my={1}
          fontSize={"0.8rem"}
          variant={"body1"}
          component={"span"}
        >
          {NYHalfWidthNumberToFillWidthNumber(addressChiban)}
        </Typography>
        <FormLabel>
          <SquareFootIcon fontSize={"inherit"} />
          推計地積(㎡)
        </FormLabel>
        <Typography
          ml={2}
          my={1}
          fontSize={"0.8rem"}
          variant={"body1"}
          component={"span"}
        >
          {formatArea(area)}
        </Typography>
      </FormControl>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              label={"登記を取得"}
              disabled={!buttonState}
              onClick={() => {
                let url = "/acquirebook";
                if (prefName && cityName && location && chiban) {
                  url =
                    url +
                    "?" +
                    `prefecture=${prefName}` +
                    `&city=${cityName}` +
                    `&location=${NYHalfWidthNumberToFillWidthNumber(
                      ooazaName
                    )}${NYHalfWidthNumberToFillWidthNumber(azaName)}` +
                    `&chiban=${NYHalfWidthNumberToFillWidthNumber(chiban)}` +
                    `&realEstate=LAND`;
                }
                window.open(url, "_blank");
              }}
              size={SizingWrapperStyle.SMALL}
              variant={ButtonVariantOption.Contained}
            />
          </Grid>
          {kaokuNumberSearchFlag && handleKaokuNumberSearch && (
            <Grid item xs={6}>
              <Button
                label={"家屋番号検索"}
                onClick={handleClickKaokuNumberSearchButton}
                size={SizingWrapperStyle.SMALL}
                variant={ButtonVariantOption.Contained}
              ></Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
