import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchSection } from "../components/SearchSection";
import type { DataGridRow, DownloadCondition, SearchCondition } from "../types";
import { StoredPicturesFeedGrid } from "../components/StoredPicturesFeedGrid";
import { downloadAPI, getStoredPicturesListAPI } from "../api";
import { useApiClient } from "@/hooks/useApiClient";
import { CustomModal } from "@/components/Modal";
import { Button, ButtonVariantOption, SubmitButton } from "@/components/Button";
import { GetPrefecturesAPI } from "@/api";
import { type IPrefecture } from "@/types/prefectures";
import { type GridSelectionModel } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const MAX_DOWNLOAD_COUNT = 100;

export const StoredPictures: React.FC = () => {
  const { apiClient } = useApiClient();
  const navigate = useNavigate();

  // 結果表示部分に表示する行のState
  const [rows, setRows] = useState<DataGridRow[]>([]);

  // ダウンロード内容のState
  const defaultDownloadCondition: DownloadCondition = {
    billableData: [],
    noBillableData: [],
  };
  const [downloadCondition, setDownloadCondition] = useState<DownloadCondition>(
    defaultDownloadCondition
  );

  // ダウンロード確認モーダル表示のState
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // 都道府県リストのState
  const [prefectures, setPrefectures] = useState<IPrefecture[]>([]);

  // 選択された行のIDを管理するState
  const [selectedRowIds, setSelectedRowIds] = useState<GridSelectionModel>([]);

  // 検索項目のState
  const [searchCondition, setSearchCondition] = useState<SearchCondition>();

  useEffect(() => {
    (async () => {
      // 都道府県リストをAPIから取得する。
      const prefectures = await GetPrefecturesAPI(apiClient);
      setPrefectures(prefectures);
    })();
  }, []);

  const handleSearch = (searchCondition: SearchCondition): void => {
    (async () => {
      const response = await getStoredPicturesListAPI(
        apiClient,
        searchCondition
      );
      setSearchCondition(searchCondition);
      setRows(response);
    })();
  };

  // ダウンロードボタンを押したときにIDと金額を計算する関数
  const handleModalOpen = (): void => {
    selectedRowIds.forEach((value) => {
      const row = rows.find((rowElement) => rowElement.id === value);
      if (row != null) {
        if (row.lastUserAcquisitionDate != null) {
          // 前回ユーザー取得日が入力されている場合、料金が発生しない
          downloadCondition.noBillableData.push({
            id: row.id,
            reusable: row.reusable,
          });
        } else {
          // 前回ユーザー取得日が入力されていない場合、料金が発生する
          downloadCondition.billableData.push({
            id: row.id,
            reusable: row.reusable,
            price: row.chargeAmount,
          });
        }
      }
    });

    setIsOpen(true);
  };

  /**
   * 課金対象データの金額を合算する。
   * @returns {nunber} 合計金額
   */
  const sumChargeAmount = (): number => {
    return downloadCondition.billableData.reduce((a, x) => {
      return a + x.price;
    }, 0);
  };

  return (
    <PagePaper>
      {/* ページタイトル */}
      <Box sx={{ display: "flex", mb: 3 }}>
        <PageTitle>不動産登記名寄せ</PageTitle>
      </Box>

      {/* 検索部分 */}
      <SectionPaper>
        <SearchSection handleSearch={handleSearch} prefectures={prefectures} />
      </SectionPaper>

      {/* 余白 */}
      <Box sx={{ mt: 3 }} />

      {/* ダウンロードボタン */}
      <Box
        sx={{
          my: 3,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          label="一括ダウンロード"
          disabled={selectedRowIds.length === 0}
          onClick={handleModalOpen}
          variant={ButtonVariantOption.Contained}
        />
      </Box>

      {/* 最大ダウンロード件数文言表示 */}
      <Box
        sx={{
          my: 1,
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Typography variant="body1">
          最大{MAX_DOWNLOAD_COUNT}件まで選択できます。
        </Typography>
      </Box>

      {/* 結果表示部分 */}
      <SectionPaper>
        <StoredPicturesFeedGrid
          rows={rows}
          setIsOpen={setIsOpen}
          setDownloadCondition={setDownloadCondition}
          apiClient={apiClient}
          setSelectedRowIds={setSelectedRowIds}
          navigate={navigate}
          maxDownloadCount={MAX_DOWNLOAD_COUNT}
        />
      </SectionPaper>

      {/* 課金確認モーダル */}
      <CustomModal
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
        }}
        ariaLabelledby="download-modal"
        ariaDescribedby="download-modal-description"
      >
        <PageTitle>課金確認</PageTitle>
        <Box>
          <Typography>{sumChargeAmount()}円課金されます。</Typography>
          <Typography>よろしいですか？</Typography>

          {/* 課金対象、課金対象外件数・金額を表示 */}
          <Box
            sx={{
              my: 3,
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ bgcolor: "whitesmoke" }}>
                  <TableRow>
                    <TableCell>名前</TableCell>
                    <TableCell>件数</TableCell>
                    <TableCell>金額</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>課金対象</TableCell>
                    <TableCell>
                      {downloadCondition.billableData.length}
                    </TableCell>
                    <TableCell>{sumChargeAmount()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>課金対象外</TableCell>
                    <TableCell>
                      {downloadCondition.noBillableData.length}
                    </TableCell>
                    <TableCell>-</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box
            sx={{
              mr: 2,
              my: 3,
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <SubmitButton
              label="はい"
              onClick={() => {
                (async () => {
                  await downloadAPI(apiClient, downloadCondition, navigate);
                  if (searchCondition) {
                    const response = await getStoredPicturesListAPI(
                      apiClient,
                      searchCondition
                    );
                    setRows(response);
                  }
                })();

                setDownloadCondition(defaultDownloadCondition);
                setIsOpen(false);
              }}
              timeout={30000}
              variant={ButtonVariantOption.Contained}
            />
            <Button
              label="いいえ"
              onClick={() => {
                setDownloadCondition(defaultDownloadCondition);
                setIsOpen(false);
              }}
              variant={ButtonVariantOption.Outlined}
            />
          </Box>
        </Box>
      </CustomModal>
    </PagePaper>
  );
};
