import { useEffect } from "react";
import { type AxiosResponse, type AxiosError } from "axios";
import { SWRConfig } from "swr";
import { useCookies } from "react-cookie";
import {
  useNavigate,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Header } from "./features/header";
import { RealEstateReceptionBookFeed } from "./features/realEstateReceptionBookFeed";
import { Login } from "./features/login";
import { type IJwtResponse } from "./types/jwt";
import { type ICookiesType } from "./types/cookies";
import { useApiClient } from "./hooks/useApiClient";
import { useLogin } from "./hooks/useLogin";
import { AccountSettings } from "./features/accountSettings";
import { AcquireBook } from "./features/acquireBook";
import { AcquireMultipleBooks } from "./features/acquireMultipleBooks";
import { AcquireBookStatus } from "./features/acquireBookStatus";
import { AcquireBookList } from "./features/acquireBookList";
import { OwnerInfoRoutes } from "./features/ownerInfo";
import { CommercialBookRoutes } from "./features/commercialBook";
import { CommercialBookStatus } from "./features/commercialBookStatus";
import { ChibanViewerAccessControl } from "./features/map";
import { CustomerManagementRoutes } from "./features/customerManagement";
import { EmailManagementRoutes } from "./features/emailManagement/routes";
import { useFeatureFlags } from "./configs/featureFlag";
import { MypageRoutes } from "./features/mypage/routes";
import { UpdateChecker } from "./components/UpdateChecker";
import { StoredPictures } from "./features/storedpictures";
import { MonitoringRoutes } from "@/features/monitoring";
import { useUserData, userDataContext } from "@/hooks/useUserData";

const App: React.FC = () => {
  const {
    commercialBookList,
    customerManagement,
    emailManagement,
    mypageTop,
    realEstateRegistrationAggregationOfNames,
  } = useFeatureFlags();

  // ---
  // hooks群
  // ---
  const [cookies, setCookie] = useCookies(); // eslint-disable-line
  const typedCookies = cookies as ICookiesType;
  const location = useLocation();
  const navigate = useNavigate();
  const { apiClient } = useApiClient();
  const { isLoggedIn } = useLogin();
  const userData = useUserData();

  // ---
  // useEffect群
  // ---
  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        if (cookies.accesstoken === undefined) {
          toast.warning("ログインしてください");
          setCookie("isLogin", "false", { path: "/" });
          navigate("/login");
        } else {
          await verifyAccessToken();
        }
      } else if (location.pathname !== "/login") {
        // 未ログインでURL直打ちした場合ログイン画面に遷移する
        toast.warning("ログインしてください");
        setCookie("isLogin", "false", { path: "/" });
        navigate("/login");
      }
    })();
  }, []);

  // ---
  // function群
  // ---
  const refreshToken = async (): Promise<void> => {
    await apiClient
      .post("auth/jwt/refresh", {
        refresh: typedCookies.refreshtoken,
      })
      .then((res: AxiosResponse<IJwtResponse>) => {
        setCookie("accesstoken", res.data.access, { path: "/" });
        setCookie("refreshtoken", res.data.refresh, { path: "/" });
      })
      .catch(() => {
        // リレッシュトークンが期限切れの場合はログイン画面に遷移
        toast.warning("ログインしてください");
        setCookie("isLogin", "false", { path: "/" });
        navigate("/login");
      });
  };

  const verifyAccessToken = async (): Promise<void> => {
    await apiClient
      .post("auth/jwt/verify", {
        token: typedCookies.accesstoken,
      })
      .catch(async (err: AxiosError) => {
        if (err.response != null && err.response.status === 401) {
          // アクセストークンが期限切れの場合は
          // リフレッシュトークンを用いて新しいアクセストークンとリフレッシュトークンを取得する
          await refreshToken();
        }
      });
  };

  return (
    <userDataContext.Provider value={userData}>
      <UpdateChecker>
        <SWRConfig
          value={{
            errorRetryCount: 1,
            onError: (error: AxiosError) => {
              if (error.response?.status !== 401)
                toast.error("予期しないエラーが発生しました");
            },
          }}
        >
          <Header />
          <Routes>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path={`/login`} element={<Login />} />
            {mypageTop && (
              <Route path={`/mypage/*`} element={<MypageRoutes />} />
            )}
            <Route path={`/feed`} element={<RealEstateReceptionBookFeed />} />
            <Route path={`/account`} element={<AccountSettings />} />
            <Route path={`/acquirebook`} element={<AcquireBook />} />
            <Route
              path={`/acquiremultiplebooks`}
              element={<AcquireMultipleBooks />}
            />
            <Route
              path={`/acquirebookstatus/:id`}
              element={<AcquireBookStatus />}
            />
            <Route path={`/acquirebooklist`} element={<AcquireBookList />} />
            <Route path={`/ownerinfo/*`} element={<OwnerInfoRoutes />} />
            {commercialBookList ? (
              <Route
                path={`/commercialbook/*`}
                element={<CommercialBookRoutes />}
              />
            ) : null}
            {commercialBookList ? (
              <Route
                path={`/commercialbookstatus/:id`}
                element={<CommercialBookStatus />}
              />
            ) : null}
            <Route path="/map" element={<ChibanViewerAccessControl />} />
            {/* 顧客管理 */}
            {customerManagement ? (
              <Route
                path={`/customermanagement/*`}
                element={<CustomerManagementRoutes />}
              />
            ) : null}
            {/* メール管理 */}
            {emailManagement ? (
              <Route
                path={`/emailManagement/*`}
                element={<EmailManagementRoutes />}
              />
            ) : null}
            {/* 名寄せ */}
            {realEstateRegistrationAggregationOfNames ? (
              <Route path={`/storedpictures/*`} element={<StoredPictures />} />
            ) : null}
            {/* モニタリング */}
            <Route path={`/monitoring/*`} element={<MonitoringRoutes />} />
          </Routes>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            newestOnTop
            closeOnClick
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            theme="light"
          />
        </SWRConfig>
      </UpdateChecker>
    </userDataContext.Provider>
  );
};

export { App };
