import { z } from "zod";

// Zodスキーマ定義
const AcquireBookFormDataSchema = z.object({
  identifyName: z.string().max(256, "識別名は256文字以内です"),
  pastDays: z
    .number({ invalid_type_error: "経過日数を0日以上で入力してください" })
    .min(0, "経過日数は0日以上で入力してください")
    .max(1000, "経過日数は1000日以内で入力してください"),
});

// 定義したZodのスキーマをTypescriptの型に変換
type AcquireBookFormData = z.infer<typeof AcquireBookFormDataSchema>;

export { AcquireBookFormDataSchema, type AcquireBookFormData };
