import { PagePaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { type GridSelectionModel } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "@/components/DatePicker";
import {
  type KeyName,
  type MonitoringDetailRow,
  type MonitoringDataGridRow,
} from "../types";
import {
  AcquireBookAPI,
  AcquireBooksAPI,
  CancelMonitoringAPI,
  ExecuteMonitoringAPI,
  GetMonitoringDetailAPI,
} from "../api";
import { useApiClient } from "@/hooks/useApiClient";
import { ExecuteModal } from "../components/ExecuteModal";
import { MonitoringDetailDataGrid } from "../components/MonitoringDetailDataGrid";
import {
  type AcquireBookForm,
  MonitoringDetailFormSchema,
  type MonitoringDetailForm,
} from "../types/schemas";
import { BulkOperationButton } from "../components/BulkOperationButton";
import { AcquireBookModal } from "../components/AcquireBookModal";
import { AcquireBooksModal } from "../components/AcquireBooksModal";
import { convertBookTypeToJpn } from "@/utils/utilsAcquireBook";
import { type BookTypeEng } from "@/types/acquirebook";
import { KeyboardArrowLeft } from "@mui/icons-material";

/**
 * モニタリング詳細画面
 */
export const MonitoringDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { apiClient } = useApiClient();

  const monitoringReactHookForm = useForm<MonitoringDetailForm>({
    mode: "all",
    defaultValues: {
      startDate: null,
      endDate: null,
    },
    resolver: zodResolver(MonitoringDetailFormSchema),
  });

  const [count, setCount] = useState<number>(0); // 件数用State
  const [receptionReason, setReceptionReason] = useState<string>(""); // 登記原因用State
  const [rows, setRows] = useState<MonitoringDataGridRow[]>([]); // モニタリング詳細結果用State
  const [selectedIds, setSelectedIds] = useState<GridSelectionModel>([]); // チェックボックスやAction選択の管理用State
  const [open, setOpen] = useState<boolean>(false); // 実行するモーダル表示用State
  const [acquireBookOpen, setAcquireBookOpen] = useState<boolean>(false); // 不動産登記取得モーダル表示用State
  const [openAcquireBooks, setOpenAcquireBooks] = useState<boolean>(false); // 謄本取得するモーダル表示用State
  const [openBulk, setOpenBulk] = useState<boolean>(false); // 一括操作ボタンState
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // メニューの位置設定用State

  // 結果のチェックボックスのON/OFF時に実行される。
  const handleRowSelectionChange = (
    newSelectionModel: GridSelectionModel
  ): void => {
    setSelectedIds(newSelectionModel);
  };

  // モニタリングを実行するAPIを呼び出す。
  const onSubmitMonitoring = monitoringReactHookForm.handleSubmit(async () => {
    const { startDate, endDate } = monitoringReactHookForm.getValues();

    await ExecuteMonitoringAPI(apiClient, {
      startDate,
      endDate,
      ids: selectedIds,
    });
  });

  // 一括で謄本取得を実行するAPIを呼び出す。
  const handleAcquireBooksAPI = async (): Promise<void> => {
    // チェックが入っているデータのみリクエストに含める。
    const selectedRows = rows.filter((row) => selectedIds.includes(row.id));

    await AcquireBooksAPI(apiClient, {
      rows: selectedRows,
    });
  };

  // 個別で謄本取得を実行するAPIを呼び出す。
  const handleAcquireBookAPI = async (
    params: AcquireBookForm
  ): Promise<void> => {
    // チェックが入っているデータのみリクエストに含める。
    const selectedRows = rows.filter((row) => selectedIds.includes(row.id));

    await AcquireBookAPI(apiClient, {
      params,
      rows: selectedRows,
    });
  };

  // モニタリング登録を解除するAPIを呼び出す。
  const handleCancelMonitoringAPI = async (): Promise<void> => {
    await CancelMonitoringAPI(apiClient, { ids: selectedIds });
  };

  /**
   * resultsに「謄本取得する」時に表示するチェックボックスの初期値を設定。
   * @param {MonitoringDetailRow[]} results
   * @returns {MonitoringDataGridRow[]}
   */
  const initialize = (
    results: MonitoringDetailRow[]
  ): MonitoringDataGridRow[] => {
    return results.map((result) => ({
      ...result,
      syoyusya: {
        checked: false,
        disabled: checkDisable("syoyusya", result.realEstateType),
      },
      zennbu: {
        checked: false,
        disabled: checkDisable("zennbu", result.realEstateType),
      },
      sintaku: {
        checked: false,
        disabled: checkDisable("sintaku", result.realEstateType),
      },
      kyodo: {
        checked: false,
        disabled: checkDisable("kyodo", result.realEstateType),
      },
      genzai: {
        checked: false,
        disabled: checkDisable("genzai", result.realEstateType),
      },
      tiseki: {
        checked: false,
        disabled: checkDisable("tiseki", result.realEstateType),
      },
      densi: {
        checked: false,
        disabled: checkDisable("densi", result.realEstateType),
      },
      tieki: {
        checked: false,
        disabled: checkDisable("tieki", result.realEstateType),
      },
      tatemono: {
        checked: false,
        disabled: checkDisable("tatemono", result.realEstateType),
      },
    }));
  };

  /**
   * 各種別でチェックボックスの活性非活性を判定。
   * /features/acquireMultipleBooks/presenter.tsxのcheckDisable()を参考にした。
   * @param {KeyName} keyName
   * @param {BookTypeEng} realEstateType
   * @returns {boolean}
   */
  const checkDisable = (
    keyName: KeyName,
    realEstateType: BookTypeEng
  ): boolean => {
    const table: Record<string, Record<string, boolean>> = {
      syoyusya: { LAND: false, BUILDING: false, CONDOMINIUM: false },
      zennbu: { LAND: false, BUILDING: false, CONDOMINIUM: false },
      sintaku: { LAND: false, BUILDING: false, CONDOMINIUM: false },
      kyodo: { LAND: false, BUILDING: false, CONDOMINIUM: false },
      genzai: { LAND: false, BUILDING: false, CONDOMINIUM: false },
      tiseki: { LAND: false, BUILDING: true, CONDOMINIUM: true },
      densi: { LAND: false, BUILDING: true, CONDOMINIUM: true },
      tieki: { LAND: false, BUILDING: true, CONDOMINIUM: true },
      tatemono: { LAND: true, BUILDING: false, CONDOMINIUM: false },
    };

    return table[keyName][realEstateType] ?? false;
  };

  useEffect(() => {
    (async () => {
      const response = await GetMonitoringDetailAPI(apiClient, {
        id: Number(id),
      });

      setCount(response.count);
      setReceptionReason(response.receptionReason);
      setRows(
        initialize(
          response.results.map((result) => {
            return {
              ...result,
              realEstateTypeJpn: convertBookTypeToJpn(result.realEstateType), // 種別を英語から日本語に変換。
            };
          })
        )
      );
    })();
  }, []);

  return (
    <PagePaper>
      {/* ページタイトル */}
      <PageTitle>不動産登記モニタリング詳細</PageTitle>

      {/* 件数と登記原因 */}
      <Stack direction={"column"} spacing={1} sx={{ mt: 3 }}>
        <Stack direction={"row"} spacing={1}>
          <Typography>件数：</Typography>
          <Typography sx={{ color: "#DF9B4A", fontWeight: "bold" }}>
            {count}
          </Typography>
          <Typography>件</Typography>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography>登記原因：</Typography>
          <Typography sx={{ color: "#DF9B4A", fontWeight: "bold" }}>
            {receptionReason}
          </Typography>
        </Stack>
      </Stack>

      {/* 一括操作ボタン */}
      <Box sx={{ mt: 3 }}>
        <BulkOperationButton
          anchorEl={anchorEl}
          handleCancelMonitoringAPI={handleCancelMonitoringAPI}
          openBulk={openBulk}
          selectedIds={selectedIds}
          setAnchorEl={setAnchorEl}
          setOpenAcquireBooks={setOpenAcquireBooks}
          setOpenBulk={setOpenBulk}
        />
      </Box>

      {/* 登記年月を指定してモニタリング実行 */}
      <Box sx={{ mt: 3 }}>
        <Typography sx={{ fontSize: "20px" }}>
          登記年月を指定してモニタリング実行
        </Typography>

        <Stack direction={"row"} spacing={1} sx={{ mt: 1 }}>
          <Stack direction={"column"}>
            <Controller
              name="startDate"
              control={monitoringReactHookForm.control}
              render={({ field }) => (
                <DatePicker
                  label="登記年月開始日"
                  onChange={(value) => {
                    monitoringReactHookForm.setValue("startDate", value, {
                      shouldValidate: true,
                    });
                  }}
                  value={field.value}
                />
              )}
            />
            {monitoringReactHookForm.formState.errors.startDate != null ? (
              <Typography sx={{ color: "red" }}>
                {monitoringReactHookForm.formState.errors.startDate.message}
              </Typography>
            ) : null}
          </Stack>
          <Stack direction={"column"}>
            <Controller
              name="endDate"
              control={monitoringReactHookForm.control}
              render={({ field }) => (
                <DatePicker
                  label="登記年月終了日"
                  onChange={(value) => {
                    monitoringReactHookForm.setValue("endDate", value, {
                      shouldValidate: true,
                    });
                  }}
                  value={field.value}
                />
              )}
            />
            {monitoringReactHookForm.formState.errors.endDate != null ? (
              <Typography sx={{ color: "red" }}>
                {monitoringReactHookForm.formState.errors.endDate.message}
              </Typography>
            ) : null}
          </Stack>

          {/* 実行するボタン */}
          <Button
            disabled={selectedIds.length === 0}
            onClick={() => {
              setOpen(true);
            }}
            variant="contained"
          >
            実行する
          </Button>
        </Stack>
      </Box>

      {/* 結果 */}
      <Box sx={{ mt: 3 }}>
        <MonitoringDetailDataGrid
          handleRowSelectionChange={handleRowSelectionChange}
          navigate={navigate}
          rows={rows}
          setSelectedIds={setSelectedIds}
          setAcquireBookOpen={setAcquireBookOpen}
        />
      </Box>

      {/* 戻るリンク */}
      <Box sx={{ mt: 3 }}>
        <Link href={"/monitoring"} sx={{ display: "inline-block" }}>
          <Box sx={{ display: "flex" }}>
            <KeyboardArrowLeft />
            <Typography>不動産登記モニタリング一覧に戻る</Typography>
          </Box>
        </Link>
      </Box>

      {/* 実行するモーダル */}
      <ExecuteModal
        open={open}
        setOpen={setOpen}
        onSubmit={onSubmitMonitoring}
      />

      {/* 不動産登記取得モーダル */}
      <AcquireBookModal
        acquireBookOpen={acquireBookOpen}
        handleAcquireBookAPI={handleAcquireBookAPI}
        handleClose={() => {
          setAcquireBookOpen(false);
          setSelectedIds([]);
        }}
        rows={rows}
        selectedIds={selectedIds}
        setAcquireBooksRows={setRows}
      />

      {/* 一括取得モーダル */}
      <AcquireBooksModal
        handleAcquireBooksAPI={handleAcquireBooksAPI}
        handleClose={() => {
          setAnchorEl(null);
          setOpenAcquireBooks(false);
          setOpenBulk(false);
          setRows(initialize(rows));
        }}
        openAcquireBooks={openAcquireBooks}
        rows={rows}
        selectedIds={selectedIds}
        setAcquireBooksRows={setRows}
      />
    </PagePaper>
  );
};
