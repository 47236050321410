import {
  Box,
  Button as MuiButton,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";

import { PagePaper, SectionPaper } from "../../components/Paper";
import { PageTitle } from "../../components/Title";
import { FeedGrid } from "./components/FeedGrid";
import { KeyboardArrowLeft } from "@mui/icons-material";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import React from "react";
import { useFeatureFlags } from "@/configs/featureFlag";

export const AcquireBookListPresenter: React.FC = () => {
  const { mypageTop } = useFeatureFlags();

  return (
    <PagePaper>
      <form>
        <Box sx={{ display: "flex" }}>
          {/* ページタイトル */}
          <Box sx={{ display: "flex", mb: 3 }}>
            <ImportContactsIcon sx={{ mr: 1 }} fontSize="large" />
            <PageTitle>不動産登記取得済み一覧</PageTitle>
          </Box>
        </Box>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs>
            <Stack my={2} direction="row" justifyContent="end" spacing={1}>
              <MuiButton
                variant="contained"
                size="medium"
                component={Link}
                href={"/acquirebook"}
                sx={{ "text-align": "center", fontWeight: "bold" }}
              >
                不動産登記/図面
                <br />
                取得（個別）
              </MuiButton>
              <MuiButton
                variant="contained"
                component={Link}
                href={"/feed#csvdialog"}
                sx={{ "text-align": "center", fontWeight: "bold" }}
              >
                不動産登記/図面
                <br />
                取得（一括）
              </MuiButton>
            </Stack>
          </Grid>
        </Grid>
        <SectionPaper>
          <FeedGrid />
          <Box sx={{ mb: 6 }} />
          <Stack
            direction={"row"}
            spacing={4}
            justifyContent={"center"}
            mb={2}
          ></Stack>
        </SectionPaper>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link
            href={mypageTop ? "/mypage" : "/feed"}
            sx={{ display: "inline-block" }}
          >
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>
                {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>
    </PagePaper>
  );
};
