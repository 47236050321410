import { ButtonVariantOption } from "@/components/Button";
import { type MenuButtonProps } from "@/features/mypage/components/MenuButton";

const ORDER_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLScyb6YBLZ2DRlMB4Djlef3fFHoyJKnxiO6I4RnYvjRqCBXK5w/viewform";
const MANUAL_URL =
  "https://trustart.notion.site/a11d52b037224591a30999b2a672e14b";
const CONTACT_US_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLSc55YOK76TfgQyXEYUoXQS6eZIC25V2qwKdILOMxV87fBCdIQ/viewform";

interface IMenuProps {
  realEstateRegistrationAggregationOfNames?: boolean;
  realEstateRegistrationMonitoring?: boolean;
  commercialBookList?: boolean;
  commercialRegistrationReceptionBook?: boolean;
  commercialRegistrationAggregationOfNames?: boolean;
  commercialRegistrationMonitoring?: boolean;
  allMattersAnalysis?: boolean;
  dmManagement?: boolean;
  emailManagement?: boolean;
  customerManagement?: boolean;
  ownerInfoList?: boolean;
  ownerInfoViaScraping?: boolean;
}

export interface BaseMenuData {
  title: string;
  level: 1 | 2;
  bgColor?: string;
}

interface IMenuData extends BaseMenuData {
  menus: MenuButtonProps[];
}

export const getMenuData = ({
  realEstateRegistrationAggregationOfNames,
  realEstateRegistrationMonitoring,
  commercialBookList,
  commercialRegistrationReceptionBook,
  commercialRegistrationAggregationOfNames,
  commercialRegistrationMonitoring,
  allMattersAnalysis,
  dmManagement,
  emailManagement,
  customerManagement,
  ownerInfoList,
}: IMenuProps): IMenuData[] => {
  return [
    {
      level: 1,
      title: "不動産登記",
      menus: [
        {
          label: "不動産登記/図面\n取得（個別）",
          variant: ButtonVariantOption.Contained,
          href: "/acquirebook",
          description:
            "所有者事項証明書、全部事項証明書、不動産図面を請求ができます。",
        },
        {
          label: "不動産登記/図面\n取得（一括）",
          variant: ButtonVariantOption.Contained,
          href: "/feed#csvdialog",
          description:
            "所有者事項証明書、全部事項証明書、不動産図面を請求ができます。",
        },
        {
          label: "不動産登記\n取得済み一覧",
          variant: ButtonVariantOption.Contained,
          href: "/acquirebooklist",
          description: "すでに取得済みの不動産登記図面の一覧を確認できます。",
        },
        {
          label: "不動産登記\n名寄せ",
          disabled: realEstateRegistrationAggregationOfNames,
          variant: ButtonVariantOption.Contained,
          href: "/storedpictures",
        },
        {
          label: "不動産登記\n受付帳",
          variant: ButtonVariantOption.Contained,
          href: "/feed",
          description:
            "不動産に関する異動（相続、売買、差し押さえ 等）の情報を閲覧できます。",
        },
        {
          label: "地図検索",
          variant: ButtonVariantOption.Contained,
          href: "/map",
          description:
            "地図から不動産登記図面の取得可能です。住居表示から地番に変換することもできます。",
        },
        {
          label: "不動産登記\nモニタリング",
          disabled: realEstateRegistrationMonitoring,
          variant: ButtonVariantOption.Contained,
        },
        {
          label: "発注",
          variant: ButtonVariantOption.Contained,
          href: ORDER_URL,
          description:
            "所有者事項証明書・全部事項証明書のデータ化の発注ができます。",
        },
      ],
    },
    {
      level: 2,
      title: "不動産登記データ化",
      menus: [
        {
          label: "所有者事項\nデータ化",
          variant: ButtonVariantOption.Contained,
          href: "/ownerinfo/upload",
          disabled: ownerInfoList,
          description:
            "所有者事項証明書のPDFをアップロードすることで、エクセルデータ化が行えます。",
        },
        {
          label: "所有者事項\n解析結果一覧",
          variant: ButtonVariantOption.Contained,
          href: "/ownerinfo/list",
          disabled: ownerInfoList,
          description:
            "すでにエクセルデータ化済みの所有者事項証明書の一覧を確認できます。",
        },
        {
          label: "全部事項\nデータ化",
          disabled: allMattersAnalysis,
          variant: ButtonVariantOption.Contained,
        },
      ],
    },
    {
      level: 1,
      title: "商業登記",
      bgColor: "#F5F5F5",
      menus: [
        {
          label: "商業・法人請求",
          href: "/commercialbook",
          disabled: commercialBookList,
          variant: ButtonVariantOption.Contained,
          description: "登記事項証明書（商業・法人登記）を請求ができます。",
        },
        {
          label: "商業登記\n取得済み一覧",
          href: "/commercialbook/list",
          disabled: commercialBookList,
          variant: ButtonVariantOption.Contained,
          description: "すでに取得済みの商業登記の一覧を確認できます。",
        },
        {
          label: "商業登記\n名寄せ",
          disabled: commercialRegistrationAggregationOfNames,
          variant: ButtonVariantOption.Contained,
        },
        {
          label: "商業登記\n受付帳",
          disabled: commercialRegistrationReceptionBook,
          variant: ButtonVariantOption.Contained,
        },
        {
          label: "商業登記\nモニタリング",
          disabled: commercialRegistrationMonitoring,
          variant: ButtonVariantOption.Contained,
        },
      ],
    },
    {
      level: 1,
      title: "顧客管理",
      menus: [
        {
          label: "顧客管理",
          variant: ButtonVariantOption.Contained,
          href: "/customermanagement/customerList",
          disabled: customerManagement,
          description:
            "顧客管理しているオーナーのご情報をもとに、メール送付をしたりテンプレ管理をしたりできます。",
        },
        {
          label: "メール管理",
          variant: ButtonVariantOption.Contained,
          href: "/emailManagement/mail/savedEmailList",
          disabled: emailManagement,
          description:
            "顧客管理しているオーナーのご情報をもとに、メール送付をしたりテンプレ管理をしたりできます。",
        },
        {
          label: "DM管理",
          disabled: dmManagement,
          variant: ButtonVariantOption.Contained,
        },
      ],
    },
    {
      level: 1,
      title: "その他",
      bgColor: "#F5F5F5",
      menus: [
        {
          label: "アカウント管理",
          variant: ButtonVariantOption.Contained,
          href: "/account",
          description: "消費クレジットの閲覧とパスワード変更ができます。",
        },
        {
          label: "マニュアル",
          variant: ButtonVariantOption.Contained,
          href: MANUAL_URL,
          description: "R.E.DATA Plusの操作マニュアルです。",
        },
        {
          label: "お問い合わせ",
          variant: ButtonVariantOption.Contained,
          href: CONTACT_US_URL,
          description: "TRUSTARTの運営チームにお問い合わせができます。",
        },
      ],
    },
  ];
};

export const replaceToSemiBlank = (text: string): string => {
  return text.replace(/\\n/g, " ");
};
