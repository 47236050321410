import { useState } from "react";
import { Typography, Popover, TablePagination, Stack } from "@mui/material";
import { ExcelExportMenuItem } from "./ExcelExportMenuItem";
import { ExcelExportWithChibanMenuItem } from "./ExcelExportWithChibanMenuItem";
import { styled } from "@mui/material/styles";
import {
  GridToolbarContainer,
  GridCsvExportMenuItem,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { FilterTradeSwitch } from "./FilterTradeSwitch";
import { type User } from "@/types/localstorage";
import { type IFilterCondition } from "../types";

interface IProps {
  disabled: boolean;
  hasTradeState: {
    setHasTrade: React.Dispatch<React.SetStateAction<boolean>>;
    hasTrade: boolean;
  };
  handleSearch: () => void;
  user: User | undefined;
  filterCondition: IFilterCondition;
  page: number;
  pageSize: number;
  allCount: number;
  rowsPerPageOptions: number[];
  changePage: (pageIndex: number) => void;
  changePageSize: (pageSize: number) => void;
}

const StyledPopover = styled(Popover)`
  // Popoverにデフォルトで設定されているbox-shadowを削除
  .MuiPopover-paper {
    box-shadow: none;
  }
`;

const CustomToolbar: React.FC<IProps> = (props) => {
  const {
    disabled,
    hasTradeState,
    handleSearch,
    user,
    filterCondition,
    page,
    pageSize,
    allCount,
    rowsPerPageOptions,
    changePage,
    changePageSize,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpenPopover = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <GridToolbarContainer
        sx={{ p: 0, display: "flex", justifyContent: "space-between" }}
      >
        <Stack direction={"row"}>
          {/* エクスポートボタン */}
          <Typography
            aria-owns={isOpenPopover ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={{
              background: disabled ? "rgba(0, 0, 0, 0.12)" : "#FFF",
              borderRadius: "4px",
              margin: "16px",
              border: disabled ? "none" : "solid #1976d2 1px",
            }}
          >
            <GridToolbarExportContainer
              disabled={disabled}
              sx={{
                // https://mui.com/material-ui/react-button/#sizes と同様のpaddingを設定
                padding: "6px 16px",

                // アイコンとテキストのセンターラインをあわせる
                "& > span": {
                  paddingBottom: "2px",
                  marginRight: "4px",
                },
              }}
            >
              <GridCsvExportMenuItem
                options={{
                  fileName: "R.E.DATA_不動産データ",
                  utf8WithBom: true,
                  allColumns: true, // 全カラム出力
                  // ここでTrueにしていても"GridColDef<R,V,F>.disableExport = true"の方が優先されるみたい
                }}
              />
              <ExcelExportMenuItem
                options={{
                  fileName: "R.E.DATA_不動産データ",
                  sheetName: "不動産登記受付帳",
                  allColumns: true,
                }}
              />
              {user?.isFree ? (
                <ExcelExportWithChibanMenuItem
                  condition={filterCondition}
                  options={{
                    fileName: "R.E.DATA_不動産データ_地番_家屋番号",
                    sheetName: "不動産登記受付帳_地番_家屋番号",
                    allColumns: true,
                  }}
                />
              ) : null}
            </GridToolbarExportContainer>
          </Typography>

          {/* 売買スクリーニング */}
          <FilterTradeSwitch
            hasTrade={hasTradeState.hasTrade}
            setHasTrade={hasTradeState.setHasTrade}
            handleSearch={handleSearch}
            disabled={disabled}
          />
        </Stack>

        {/* 上部のページネーション */}
        <TablePagination
          component={"div"}
          count={allCount}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={(_, page) => {
            changePage(page);
          }}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(event) => {
            changePageSize(Number(event.target.value));
          }}
          labelRowsPerPage={"ページあたりの行数:"}
          labelDisplayedRows={({ from, to, count }) => {
            return `${count.toLocaleString()}件中 ${from.toLocaleString()}件 〜 ${to.toLocaleString()}件`;
          }}
        />
      </GridToolbarContainer>

      {disabled && (
        <StyledPopover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={isOpenPopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: 39, // エクスポートボタンから8px離れる用に設定
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography
            sx={{
              padding: "8px 6px",
              background: "rgba(0, 0, 0, 0.54)",
              color: "#FFF",
              fontSize: "11px",
            }}
          >
            一覧をエクスポートするには1000件未満に絞り込んでください
          </Typography>
        </StyledPopover>
      )}
    </>
  );
};

export { CustomToolbar };
